import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import InputGroup from "react-bootstrap/InputGroup";
import StockHoldings from "./StockHoldings";
import compaines from "../../data/companies.json";
import { Helmet } from "react-helmet";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FormControl,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import PortfolioGraph from "./PortfolioGraph";

const Portfolio = ({ lang, mode, user, allTickers }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [loading, setLoading] = useState(true);
  const myRef = useRef(null);
  const [loggedin, setLoggedIn] = useState(false);
  const [sellSymbol, setSellSymbol] = useState("");
  const [sellDate, setSellDate] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [sellPricePlaceholder, setSellPricePlaceholder] = useState(
    localString[lang]["portfolio"]["price"]
  );
  const [sellTotal, setSellTotal] = useState("");
  const [sellCount, setSellCount] = useState("");
  const [presetName, setPresetName] = useState(null);
  const [buying, setBuying] = useState(false);
  const [holdings, setHoldings] = useState([]);
  const [portfolioData, setPortfolioData] = useState(null);
  const [portfolioName, setPortfolioName] = useState([]);
  const [portfolioValue, setPortfolioValue] = useState([]);
  const [portfolioDiff, setPortfolioDiff] = useState([]);
  const [portfolioDiffPct, setPortfolioDiffPct] = useState([]);
  const [editName, setEditName] = useState(false);
  const [newName, setNewName] = useState("");
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDesc, setMetaDesc] = useState(null);

  const SymbolRender = (p) => {
    if (p.data?.symbol === undefined) return null;

    return `${p.data?.symbol} | ${p.data?.company_name}`;
  };

  const pnlRender = (p) => {
    if (p.data?.p_and_l === undefined) return null;
    if (isNaN(parseFloat(p.data?.p_and_l))) return "-";
    let growth =
      (parseFloat(p.data?.p_and_l) * 100) /
      (parseFloat(p.data?.cost_price) * parseFloat(p.data?.no_of_shares));
    return (
      <div>
        {parseFloat(growth) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(parseFloat(growth).toFixed(2))}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(parseFloat(growth).toFixed(2))}%`}
          </span>
        )}
      </div>
    );
  };
  const CPRender = (p) => {
    if (p.data?.cost_price === undefined) return null;

    return `$${parseFloat(p.data?.cost_price).toFixed(2)}`;
  };
  const SPRender = (p) => {
    if (p.data?.selling_price === undefined) return null;

    return `$${parseFloat(p.data?.selling_price).toFixed(2)}`;
  };

  function insertParam(key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    var kvp = document.location.search.substr(1).split("&");
    let i = 0;

    for (; i < kvp.length; i++) {
      if (kvp[i].startsWith(key + "=")) {
        let pair = kvp[i].split("=");
        pair[1] = value;
        kvp[i] = pair.join("=");
        break;
      }
    }

    if (i >= kvp.length) {
      kvp[kvp.length] = [key, value].join("=");
    }

    // can return this or...
    let params = kvp.join("&");

    // reload page with new params
    document.location.search = params;
  }

  const fetchHoldings = () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.getStockHoldings}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      WebService.get(fullUrl, {
        headers: headers,
      }).then((response) => {
        if (response?.data) {
          setHoldings(response?.data);
        } else {
          setHoldings([]);
        }
      });
    }
  };

  const fetchPortfolio = () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.portfolio}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      WebService.get(fullUrl, {
        headers: headers,
      }).then((response) => {
        if (response?.data) {
          let apiData = response?.data;
          let curValue = apiData?.current_value;
          let prevValue = apiData?.prev_value;
          let diff = curValue - prevValue;
          let diffPct = prevValue === 0 ? 100 : (diff * 100) / prevValue;

          setPortfolioData(apiData);
          setPortfolioName(apiData?.name);
          setPortfolioValue(curValue);
          setPortfolioDiff(diff);
          setPortfolioDiffPct(diffPct);
          let title = `${apiData?.name} Simulated Stock Portfolio`;
          let desc = `Explore the top stocks in the simulated portfolio, ${apiData?.name}. Track buy/sell decisions, unrealized profits, and key details like purchase date, P/E ratio, and share price changes to analyze performance.`;
          setMetaTitle(title);
          setMetaDesc(desc);
        } else {
          setHoldings([]);
        }
      });
    }
  };

  useEffect(() => {
    if (!dataExists(user)) {
      window.location = "/";
    }
    setLoggedIn(dataExists(user));
    fetchPortfolio();
    fetchHoldings();
  }, []);

  useEffect(() => {
    if (sellPrice && sellCount) {
      let _total = parseFloat(sellPrice) * parseFloat(sellCount);
      setSellTotal(`$${nFormatter(_total, 2)}`);
    } else {
      setSellTotal("");
    }
  }, [sellPrice, sellCount]);

  useEffect(() => {
    if (gridApi) {
      const dataSource = customDataSource();
      gridApi.setGridOption("datasource", dataSource);
      setIsFirstLoad(false);
    }
  }, [gridApi]);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "symbol",
      headerName: localString[lang]["portfolio"]["symbol"],
      cellRenderer: SymbolRender,
      minWidth: 300,
      sortable: false,
    },
    {
      field: "cost_price",
      headerName: localString[lang]["portfolio"]["purchasePrice"],
      cellRenderer: CPRender,
      minWidth: 120,
      sortable: false,
    },
    {
      field: "date_of_purchase",
      headerName: localString[lang]["portfolio"]["purchaseDate"],
      minWidth: 120,
      sortable: false,
    },
    {
      field: "selling_price",
      headerName: localString[lang]["portfolio"]["priceSold"],
      cellRenderer: SPRender,
      minWidth: 120,
      sortable: false,
    },
    {
      field: "date_of_sale",
      headerName: localString[lang]["portfolio"]["dateSold"],
      minWidth: 120,
      sortable: false,
    },
    {
      field: "no_of_shares",
      headerName: localString[lang]["portfolio"]["noOfShares"],
      minWidth: 80,
      sortable: false,
    },
    {
      field: "p_and_l",
      headerName: localString[lang]["portfolio"]["pctReturns"],
      cellRenderer: pnlRender,
      minWidth: 150,
      sortable: false,
    },
  ]);

  const customDataSource = () => {
    return {
      getRows: (params) => {
        setLoading(true);
        const { startRow, endRow } = params;
        const pageSize = endRow - startRow; // Page size
        const pageNumber = startRow / pageSize + 1; // Calculate page number

        if (dataExists(user)) {
          let headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          };

          let fullUrl = `${apiEndPoints.transactions}/?page=${pageNumber}&page_size=${pageSize}`;
          WebService.get(fullUrl, { headers: headers })
            .then((response) => {
              let data = response?.data;
              params.successCallback(data?.results, data?.count);
              setLoading(false);
              // setTotalCount(data?.count);
              // setUpdateDate(updateDate.toDateString());
            })
            .catch((error) => {
              params.failCallback();
              console.error("Error fetching data:", error);
              setLoading(false);
            });
        }
      },
    };
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const saveName = async () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.portfolioName}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        name: newName,
      };
      try {
        let response = await WebService.post(fullUrl, payload, {
          headers: headers,
        });
        if (response?.status >= 200 && response?.status < 300) {
          setPortfolioName(newName);
          setEditName(false);

          // loadPresets();
        } else {
          throw response;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (sellSymbol) {
      let fullUrl = `${apiEndPoints.getStockInfo}/${sellSymbol?.ticker}`;
      WebService.get(fullUrl).then((response) => {
        let curData = response?.data;
        let price = parseFloat(curData?.price).toFixed(3);
        setSellPricePlaceholder(`$${price}`);
      });
    }
  }, [sellSymbol]);

  const handleBuy = async (e) => {
    e.preventDefault();
    setBuying(true);

    if (dataExists(user)) {
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        symbol: sellSymbol?.ticker,
        date: sellDate,
        cost_price: sellPrice,
        no_of_shares: sellCount,
      };
      let fullUrl = `${apiEndPoints.buy}/`;

      try {
        let response = await WebService.post(fullUrl, payload, {
          headers: headers,
        });
        if (response?.status >= 200 && response?.status < 300) {
          fetchHoldings();
          setSellCount("");
          setSellDate("");
          setSellPrice("");
          setSellSymbol("");
          myRef.current.getInstance().clear();
          myRef.current.state.selected = [];
          setBuying(false);
        } else {
          throw response;
        }
      } catch (error) {
        setBuying(false);
        console.log(error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>

      <Stack
        className={`content screener ${
          mode === "dark" ? "background-dark" : "background-light"
        }`}
        gap={3}
      >
        <div className="ps-4 pe-4 pt-2">
          <Row>
            {portfolioData && (
              <Col className="mt-2">
                <div>
                  <div className="mb-3">
                    {editName ? (
                      <>
                        <InputGroup className="mb-3 name-edit-input">
                          <Form.Control
                            placeholder={
                              localString[lang]["portfolio"]["portfolioName"]
                            }
                            aria-label={
                              localString[lang]["portfolio"]["portfolioName"]
                            }
                            aria-describedby="basic-addon2"
                            onChange={(e) => {
                              setNewName(e.target.value);
                            }}
                          />
                          <InputGroup.Text
                            className="rounded-end"
                            id="basic-addon2"
                          >
                            Portfolio
                          </InputGroup.Text>{" "}
                          <i
                            onClick={() => {
                              saveName();
                            }}
                            className="align-content-center ms-2 cursor-pointer ps-2 bi bi-floppy"
                          ></i>
                        </InputGroup>
                      </>
                    ) : (
                      <>
                        <span className="h4">{`${portfolioName} Portfolio`}</span>{" "}
                        <i
                          onClick={() => {
                            setEditName(true);
                          }}
                          className="cursor-pointer ps-2 bi bi-pencil-square"
                        ></i>
                      </>
                    )}
                  </div>
                  <p className="m-0">
                    <span className="h3">
                      $
                      {parseFloat(
                        parseFloat(portfolioValue).toFixed(3)
                      ).toLocaleString()}
                    </span>
                    <span className="ps-2">
                      {parseFloat(portfolioDiffPct) > 0 ? (
                        <span className="chart-numbers-change-green">
                          <i className="bi bi-arrow-up-short"></i>
                          {`${Math.abs(
                            parseFloat(portfolioDiffPct).toFixed(2)
                          )}%`}
                        </span>
                      ) : (
                        <span className="chart-numbers-change-red">
                          <i className="bi bi-arrow-down-short"></i>
                          {`${Math.abs(
                            parseFloat(portfolioDiffPct).toFixed(2)
                          )}%`}
                        </span>
                      )}
                    </span>
                  </p>
                  <p>
                    <span className="">
                      {parseFloat(portfolioDiff) > 0 ? (
                        <span className="chart-numbers-change-green">
                          <i className="bi bi-arrow-up-short"></i>
                          {`$${Math.abs(
                            parseFloat(portfolioDiff).toFixed(2)
                          ).toLocaleString()}`}
                        </span>
                      ) : (
                        <span className="chart-numbers-change-red">
                          <i className="bi bi-arrow-down-short"></i>
                          {`$${Math.abs(
                            parseFloat(portfolioDiff).toFixed(2)
                          ).toLocaleString()}`}
                        </span>
                      )}
                    </span>
                  </p>
                </div>
              </Col>
            )}
          </Row>
        </div>
        <Container fluid>
          <Row>
            {/* Left section: 66% width for the graph */}
            <Col md={8} className="pb-4">
              <PortfolioGraph mode={mode} user={user} lang={lang} />
            </Col>

            {/* Right section: 33% width for the form */}
            <Col md={4} className="pb-4">
              <Form onSubmit={handleBuy}>
                {/* Symbol Input */}
                <Form.Group controlId="formSymbol">
                  <Form.Label>
                    {localString[lang]["portfolio"]["symbol"]}
                  </Form.Label>
                  <Typeahead
                    ref={myRef}
                    id={`typehead-sell`}
                    input={`.typehead-sell`}
                    onChange={(data) => {
                      setSellSymbol(data[0]);
                    }}
                    options={compaines}
                    value={sellSymbol}
                    placeholder={localString[lang].SearchBarPlaceholder}
                  />
                </Form.Group>

                {/* Date Input */}
                <Form.Group className="pt-3" controlId="formDate">
                  <Form.Label>
                    {localString[lang]["portfolio"]["date"]}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={sellDate}
                    onChange={(e) => {
                      setSellDate(e.target.value);
                    }}
                    required
                  />
                </Form.Group>

                {/* Price Input */}
                <Form.Group controlId="formPrice" className="pt-3">
                  <Form.Label>
                    {localString[lang]["portfolio"]["price"]}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    value={sellPrice}
                    onChange={(e) => {
                      setSellPrice(e.target.value);
                    }}
                    placeholder={sellPricePlaceholder}
                    // placeholder={localString[lang]["portfolio"]["price"]}
                    step="0.01"
                    required
                  />
                </Form.Group>

                {/* Number of Shares Input */}
                <Form.Group controlId="formShares" className="pt-3">
                  <Form.Label>
                    {localString[lang]["portfolio"]["noOfShares"]}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="numberOfShares"
                    value={sellCount}
                    onChange={(e) => {
                      setSellCount(e.target.value);
                    }}
                    placeholder={localString[lang]["portfolio"]["noOfShares"]}
                    required
                  />
                </Form.Group>
                {/* Number of Shares Input */}
                <Form.Group className="pt-3">
                  <Form.Label>
                    {`${localString[lang]["portfolio"]["total"]}: ${sellTotal}`}
                  </Form.Label>
                </Form.Group>

                {/* Submit Button */}
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3"
                  disabled={
                    !(
                      sellCount &&
                      sellDate &&
                      sellPrice &&
                      sellSymbol &&
                      !buying
                    )
                  }
                >
                  {localString[lang]["portfolio"]["buy"]}
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Stack>

      <StockHoldings
        mode={mode}
        user={user}
        holdings={holdings}
        portfolioValue={portfolioValue}
        lang={lang}
      />

      <Stack
        className={`content m-4 ${
          mode === "dark" ? "background-dark" : "background-light"
        }`}
        gap={3}
        id="screener-table"
        ref={myRef}
      >
        <div className="ps-4 pe-4 pt-2">
          <Row>
            <Col xs={6} className="mt-2">
              {localString[lang]["portfolio"]["sellTransactions"]}
            </Col>
          </Row>
        </div>

        <div
          className={`ag-theme-${mode === "dark" ? "quartz-dark" : "quartz"}`}
          style={{ height: "300px" }}
        >
          <AgGridReact
            // rowData={rowData}
            loading={loading}
            columnDefs={columnDefs}
            autoSizeStrategy={{ type: "fitGridWidth" }}
            rowModelType="infinite"
            pagination={true}
            paginationPageSize={25}
            cacheBlockSize={25}
            onGridReady={onGridReady}
            paginationPageSizeSelector={[25, 50, 100]}
            // onSortChanged={onSortChanged}
          />
        </div>
      </Stack>
    </>
  );
};

export default Portfolio;
