import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";

const Screener = ({ lang, mode, user }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [exchange, setExchange] = useState("any");
  const [marketCap, setMarketCap] = useState("any");
  const [performance, setPerformance] = useState("any");
  const [pe, setPE] = useState("any");
  const [pb, setPB] = useState("any");
  const [ps, setPS] = useState("any");
  const [peg, setPEG] = useState("any");
  const [quickRatio, setQuickRatio] = useState("any");
  const [currentRatio, setCurrentRatio] = useState("any");
  const [sector, setSector] = useState("any");
  const [industry, setIndustry] = useState("any");
  const [priceCash, setPriceCash] = useState("any");
  const [operatingMargin, setOperatingMargin] = useState("any");
  const [peHistoric, setPeHistoric] = useState("any");
  const [pbHistoric, setPbHistoric] = useState("any");
  const [psHistoric, setPsHistoric] = useState("any");
  const [pfcf, setPfcf] = useState("any");
  const [dividendYield, setDividendYield] = useState("any");
  const [payoutRatio, setPayoutRatio] = useState("any");
  const [netProfitMargin, setNetProfitMargin] = useState("any");
  const [deRatio, setDeRatio] = useState("any");
  const [totalCount, setTotalCount] = useState(null);
  const [updateDate, setUpdateDate] = useState(null);
  const [grossMargin, setGrossMargin] = useState("any");
  const [ltdc, setLtdc] = useState("any");
  const [revenueGrowth, setRevenueGrowth] = useState("any");
  const [revenueGrowthHist, setRevenueGrowthHist] = useState("any");
  const [netIncomeGrowth, setNetIncomeGrowth] = useState("any");
  const [searchParams, setSearchParams] = useSearchParams();
  const myRef = useRef(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [loggedin, setLoggedIn] = useState(false);
  const [presetData, setPresetData] = useState([
    {
      id: 0,
      name: localString[lang]["selectPreset"],
      url: "#",
    },
  ]);
  const [presetName, setPresetName] = useState(null);
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDesc, setMetaDesc] = useState(null);

  const RankColRender = (p) => {
    return `${p.node.rowIndex + 1}`;
  };

  const TodayColRender = (p) => {
    if (p.data?.price_changes === undefined) return null;
    if (isNaN(parseFloat(p.data?.price_changes))) return "-";
    return (
      <div>
        {parseFloat(p.data?.price_changes) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(parseFloat(p.data?.price_changes).toFixed(3))}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(parseFloat(p.data?.price_changes).toFixed(3))}%`}
          </span>
        )}
      </div>
    );
  };
  const peRender = (p) => {
    if (p.data?.pe_ratio_ttm === undefined) return null;
    if (isNaN(parseFloat(p.data?.pe_ratio_ttm))) return "-";
    return (
      <div>
        {parseFloat(p.data?.pe_ratio_ttm).toFixed(3)}
        {/* {parseFloat(p.data?.price_performance_1M) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1M).toFixed(3)
            )}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1M).toFixed(3)
            )}%`}
          </span>
        )} */}
      </div>
    );
  };
  const peHistoricalRender = (p) => {
    if (p.data?.pe_ratio_ttm_historical === undefined) return null;
    if (isNaN(parseFloat(p.data?.pe_ratio_ttm_historical))) return "-";
    return (
      <div>
        {parseFloat(p.data?.pe_ratio_ttm_historical).toFixed(3)}
        {/* {parseFloat(p.data?.price_performance_1Y) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1Y).toFixed(3)
            )}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1Y).toFixed(3)
            )}%`}
          </span>
        )} */}
      </div>
    );
  };
  const MarketCapRender = (p) => {
    if (p.data?.market_cap === undefined) return null;
    return `$${nFormatter(p.data?.market_cap, 4)}`;
  };
  const PriceRender = (p) => {
    if (p.data?.price === undefined) return null;

    return `$${nFormatter(p.data?.price, 4)}`;
  };
  const NameRender = (p) => {
    return (
      <a target="__blank" href={`/companies/stock-metrics/${p.data?.symbol}`}>
        {" "}
        {p.data?.company_name}{" "}
      </a>
    );
  };

  const getTitle = (type) => {
    return (
      <Col xs={6} className="data-value mt-1">
        {localString[lang]["scanner"][type]}
      </Col>
    );
  };

  function insertParam(key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    var kvp = document.location.search.substr(1).split("&");
    let i = 0;

    for (; i < kvp.length; i++) {
      if (kvp[i].startsWith(key + "=")) {
        let pair = kvp[i].split("=");
        pair[1] = value;
        kvp[i] = pair.join("=");
        break;
      }
    }

    if (i >= kvp.length) {
      kvp[kvp.length] = [key, value].join("=");
    }

    // can return this or...
    let params = kvp.join("&");

    // reload page with new params
    document.location.search = params;
  }

  const setFilters = (filterString) => {
    if (filterString !== null) {
      let curCount = 0;
      let titleStr = "";
      let filterArray = filterString.split(",");
      for (let i = 0; i < filterArray.length; i++) {
        let curFilter = undefined;
        if (filterArray[i].startsWith("ex_")) {
          setExchange(filterArray[i]);
        } else if (filterArray[i].startsWith("mcap_")) {
          setMarketCap(filterArray[i]);
          curFilter = "marketCap";
        } else if (filterArray[i].startsWith("perf_")) {
          setPerformance(filterArray[i]);
          curFilter = "performance";
        } else if (filterArray[i].startsWith("pe_")) {
          setPE(filterArray[i]);
          curFilter = "pe";
        } else if (filterArray[i].startsWith("peg_")) {
          setPEG(filterArray[i]);
          curFilter = "peg";
        } else if (filterArray[i].startsWith("pb_")) {
          setPB(filterArray[i]);
          curFilter = "pb";
        } else if (filterArray[i].startsWith("ps_")) {
          setPS(filterArray[i]);
          curFilter = "ps";
        } else if (filterArray[i].startsWith("quick_")) {
          setQuickRatio(filterArray[i]);
          curFilter = "quick";
        } else if (filterArray[i].startsWith("current_")) {
          setCurrentRatio(filterArray[i]);
          curFilter = "current";
        } else if (filterArray[i].startsWith("sector_")) {
          setSector(filterArray[i]);
          curFilter = "sector";
        } else if (filterArray[i].startsWith("ind_")) {
          setIndustry(filterArray[i]);
          curFilter = "industry";
        } else if (filterArray[i].startsWith("pricecash_")) {
          setPriceCash(filterArray[i]);
          curFilter = "pricecash";
        } else if (filterArray[i].startsWith("opmr_")) {
          setOperatingMargin(filterArray[i]);
          curFilter = "operatingMargin";
        } else if (filterArray[i].startsWith("pehistoric_")) {
          setPeHistoric(filterArray[i]);
          curFilter = "peHistoric";
        } else if (filterArray[i].startsWith("pbhistoric_")) {
          setPbHistoric(filterArray[i]);
          curFilter = "pbHistoric";
        } else if (filterArray[i].startsWith("pshistoric_")) {
          setPsHistoric(filterArray[i]);
          curFilter = "psHistoric";
        } else if (filterArray[i].startsWith("pfcf_")) {
          setPfcf(filterArray[i]);
          curFilter = "pfcf";
        } else if (filterArray[i].startsWith("divy_")) {
          setDividendYield(filterArray[i]);
          curFilter = "dividendYield";
        } else if (filterArray[i].startsWith("payoutr_")) {
          setPayoutRatio(filterArray[i]);
          curFilter = "payoutRatio";
        } else if (filterArray[i].startsWith("netprf_")) {
          setNetProfitMargin(filterArray[i]);
          curFilter = "netProfitMargin";
        } else if (filterArray[i].startsWith("deratio_")) {
          setDeRatio(filterArray[i]);
          curFilter = "deRatio";
        } else if (filterArray[i].startsWith("grossm_")) {
          setGrossMargin(filterArray[i]);
          curFilter = "grossMargin";
        } else if (filterArray[i].startsWith("ltdc_")) {
          setLtdc(filterArray[i]);
          curFilter = "ltdc";
        } else if (filterArray[i].startsWith("revgrow_")) {
          setRevenueGrowth(filterArray[i]);
          curFilter = "revgrow";
        } else if (filterArray[i].startsWith("revgrowavg_")) {
          setRevenueGrowthHist(filterArray[i]);
          curFilter = "revgrowavg";
        } else if (filterArray[i].startsWith("netincgrow_")) {
          setNetIncomeGrowth(filterArray[i]);
          curFilter = "netincgrow";
        }
        if (curCount === 0 && curFilter) {
          titleStr += `${localString["en"]["scanner"][curFilter]} ${
            localString["en"]["scanner"][filterArray[i]]
          }`;
          curCount++;
        } else if (curCount === 1 && curFilter) {
          titleStr += ` & ${localString["en"]["scanner"][curFilter]} ${
            localString["en"]["scanner"][filterArray[i]]
          }`;
          curCount++;
        }
      }

      setMetaTitle(
        `Stock Screener Page ${
          titleStr ? "Sorted By " + titleStr : "to analyze key metrics"
        }`
      );
      setMetaDesc(
        `Use our stock screener to find companies with ${titleStr}. Analyze key metrics including market capitalization, P/B and P/S ratios, and recent share price performance over multiple timeframes.`
      );
    }
  };

  const loadPresets = () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.getPresets}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      // try {
      WebService.get(fullUrl, {
        headers: headers,
      }).then((response) => {
        let anyData = [
          {
            id: 0,
            name: localString[lang]["selectPreset"],
            url: "#",
          },
        ];
        if (response?.data) {
          setPresetData(anyData.concat(response?.data));
        } else {
          setPresetData(anyData);
        }
      });
    }
  };

  useEffect(() => {
    let filters = searchParams.get("f");
    let order = searchParams.get("o");
    if (
      order !== null &&
      order !== "" &&
      order !== "null" &&
      order !== undefined
    ) {
      myRef.current.scrollIntoView();
      setOrder(order);
    }
    setFilters(filters);
    setLoggedIn(dataExists(user));
    loadPresets();
  }, []);

  useEffect(() => {
    let filters = searchParams.get("f");
    let order = searchParams.get("o");
    if (gridApi) {
      const dataSource = customDataSource(filters, order);
      gridApi.setGridOption("datasource", dataSource);
      setIsFirstLoad(false);
    }
  }, [gridApi]);

  useEffect(() => {
    if (isFirstLoad === true) return;
    let f = getFilterArray(getFilters());
    // if (f.length > 0) {
    insertParam("f", f.toString());
    // }
  }, [
    exchange,
    marketCap,
    performance,
    pe,
    pb,
    ps,
    peg,
    quickRatio,
    currentRatio,
    sector,
    priceCash,
    operatingMargin,
    peHistoric,
    pbHistoric,
    psHistoric,
    pfcf,
    dividendYield,
    payoutRatio,
    netProfitMargin,
    deRatio,
    grossMargin,
    ltdc,
    revenueGrowth,
    revenueGrowthHist,
    industry,
    netIncomeGrowth,
  ]);

  const getFilters = () => {
    return {
      exchange,
      marketCap,
      performance,
      pe,
      pb,
      ps,
      peg,
      quickRatio,
      currentRatio,
      sector,
      priceCash,
      operatingMargin,
      peHistoric,
      pbHistoric,
      psHistoric,
      pfcf,
      dividendYield,
      payoutRatio,
      netProfitMargin,
      deRatio,
      grossMargin,
      ltdc,
      revenueGrowth,
      revenueGrowthHist,
      industry,
      netIncomeGrowth,
    };
  };

  const getFilterArray = (filters) => {
    let f = [];

    if (filters.exchange !== "any") {
      f.push(filters.exchange);
    }
    if (filters.marketCap !== "any") {
      f.push(filters.marketCap);
    }
    if (filters.performance !== "any") {
      f.push(filters.performance);
    }
    if (filters.pe !== "any") {
      f.push(filters.pe);
    }
    if (filters.peg !== "any") {
      f.push(filters.peg);
    }
    if (filters.pb !== "any") {
      f.push(filters.pb);
    }
    if (filters.ps !== "any") {
      f.push(filters.ps);
    }
    if (filters.quickRatio !== "any") {
      f.push(filters.quickRatio);
    }
    if (filters.currentRatio !== "any") {
      f.push(filters.currentRatio);
    }
    if (filters.sector !== "any") {
      f.push(filters.sector);
    }
    if (filters.priceCash !== "any") {
      f.push(filters.priceCash);
    }
    if (filters.operatingMargin !== "any") {
      f.push(filters.operatingMargin);
    }
    if (filters.peHistoric !== "any") {
      f.push(filters.peHistoric);
    }
    if (filters.pbHistoric !== "any") {
      f.push(filters.pbHistoric);
    }
    if (filters.psHistoric !== "any") {
      f.push(filters.psHistoric);
    }
    if (filters.pfcf !== "any") {
      f.push(filters.pfcf);
    }
    if (filters.dividendYield !== "any") {
      f.push(filters.dividendYield);
    }
    if (filters.payoutRatio !== "any") {
      f.push(filters.payoutRatio);
    }
    if (filters.netProfitMargin !== "any") {
      f.push(filters.netProfitMargin);
    }
    if (filters.deRatio !== "any") {
      f.push(filters.deRatio);
    }
    if (filters.grossMargin !== "any") {
      f.push(filters.grossMargin);
    }
    if (filters.ltdc !== "any") {
      f.push(filters.ltdc);
    }
    if (filters.revenueGrowth !== "any") {
      f.push(filters.revenueGrowth);
    }
    if (filters.revenueGrowthHist !== "any") {
      f.push(filters.revenueGrowthHist);
    }
    if (filters.industry !== "any") {
      f.push(filters.industry);
    }
    if (filters.netIncomeGrowth !== "any") {
      f.push(filters.netIncomeGrowth);
    }
    return f;
  };

  const customDataSource = (filters = null, sort = null) => {
    return {
      getRows: (params) => {
        setLoading(true);
        const { startRow, endRow } = params;
        const pageSize = endRow - startRow; // Page size
        const pageNumber = startRow / pageSize + 1; // Calculate page number

        let fullUrl = `${apiEndPoints.getCompanyList}/?page=${pageNumber}&page_size=${pageSize}`;
        if (
          filters !== null &&
          filters !== "" &&
          filters !== "null" &&
          filters !== undefined
        ) {
          fullUrl = `${fullUrl}&f=${filters}`;
        }
        if (
          sort !== null &&
          sort !== "" &&
          sort !== "null" &&
          sort !== undefined
        ) {
          fullUrl = `${fullUrl}&o=${sort}`;
        }
        WebService.get(fullUrl)
          .then((response) => {
            let data = response?.data;
            let updateDate = new Date(
              Math.max.apply(
                null,
                data?.results?.map(function (e) {
                  return new Date(e.updated_at);
                })
              )
            );
            params.successCallback(data?.results, data?.count);
            setLoading(false);
            setTotalCount(data?.count);
            setUpdateDate(updateDate.toDateString());
          })
          .catch((error) => {
            params.failCallback();
            console.error("Error fetching data:", error);
            setLoading(false);
          });
      },
    };
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    let columns = [
      {
        field: "rank",
        headerName: localString[lang].rank,
        cellRenderer: RankColRender,
        maxWidth: 100,
        minWidth: 80,
        sortable: false,
      },
      {
        field: "company_name",
        headerName: localString[lang].companyName,
        cellRenderer: NameRender,
        minWidth: 300,
        sortable: true,
      },
      {
        field: "symbol",
        headerName: localString[lang].symbol,
        minWidth: 100,
        maxWidth: 120,
        sortable: false,
      },
      {
        field: "sector",
        headerName: localString[lang].Sector,
        minWidth: 200,
        sortable: false,
      },
      {
        field: "industry",
        headerName: localString[lang].Industry,
        minWidth: 250,
        sortable: false,
      },
      {
        field: "market_cap",
        headerName: localString[lang].marketCap,
        cellRenderer: MarketCapRender,
        minWidth: 120,
        sortable: true,
      },
      {
        field: "price",
        headerName: localString[lang].price,
        cellRenderer: PriceRender,
        minWidth: 120,
        maxWidth: 120,
        sortable: true,
      },
      {
        field: "price_changes",
        headerName: localString[lang].today,
        cellRenderer: TodayColRender,
        minWidth: 150,
        maxWidth: 150,
        sortable: true,
      },
      {
        field: "pe_ratio_ttm",
        headerName: localString[lang].PE,
        cellRenderer: peRender,
        minWidth: 150,
        maxWidth: 150,
        sortable: true,
      },
      {
        field: "pe_ratio_ttm_historical",
        headerName: localString[lang].peHistoric,
        cellRenderer: peHistoricalRender,
        minWidth: 120,
        sortable: true,
      },
    ];
    let curOrder = searchParams.get("o");

    if (
      curOrder !== null &&
      curOrder !== "" &&
      curOrder !== "null" &&
      curOrder !== undefined
    ) {
      switch (curOrder) {
        case "company_name":
          columns[1]["sort"] = "asc";
          break;
        case "-company_name":
          columns[1]["sort"] = "desc";
          break;
        case "market_cap":
          columns[5]["sort"] = "asc";
          break;
        case "-market_cap":
          columns[5]["sort"] = "desc";
          break;
        case "price":
          columns[6]["sort"] = "asc";
          break;
        case "-price":
          columns[6]["sort"] = "desc";
          break;
        case "price_changes":
          columns[7]["sort"] = "asc";
          break;
        case "-price_changes":
          columns[7]["sort"] = "desc";
          break;
        case "pe_ratio_ttm":
          columns[8]["sort"] = "asc";
          break;
        case "-pe_ratio_ttm":
          columns[8]["sort"] = "desc";
          break;
        case "pe_ratio_ttm_historical":
          columns[9]["sort"] = "asc";
          break;
        case "-pe_ratio_ttm_historical":
          columns[9]["sort"] = "desc";
          break;
      }
    }
    setColumnDefs(columns);
  };

  const onSortChanged = (params) => {
    if (gridApi) {
      // const sortModel = gridApi.getSortModel();
      let sortColumns = params["columns"];
      let curSort = sortColumns[sortColumns.length - 1];
      let sortString = `${curSort["sort"] === "desc" ? "-" : ""}${
        curSort["colId"]
      }`;
      insertParam("o", sortString);
      // Trigger API call with sort info
      // fetchData(sortModel);
    }
  };

  const savePresets = async () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.savePresets}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        url: `/screener/${document.location.search}`,
        name: presetName,
      };
      try {
        let response = await WebService.post(fullUrl, payload, {
          headers: headers,
        });
        if (response?.status >= 200 && response?.status < 300) {
          loadPresets();
        } else {
          throw response;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>
      <Stack
        className={`content screener ${
          mode === "dark" ? "background-dark" : "background-light"
        }`}
        gap={3}
      >
        <div className="d-flex justify-content-between flex-row flex-wrap bd-highlight data-container mb-0">
          <div className="d-flex">
            <OverlayTrigger
              placement="top"
              overlay={
                loggedin ? (
                  <span></span>
                ) : (
                  <Tooltip id="tooltip">{localString[lang].loginToSP}</Tooltip>
                )
              }
            >
              <div className="screener-title me-4">
                {localString[lang].favoritePresets}
                <InputGroup className="pt-2">
                  <Form.Select
                    aria-label="Preset select"
                    size="sm"
                    className="nav-link-hidden"
                    // value={lang}
                    disabled={!loggedin}
                    onChange={(e) => {
                      window.location = e.target.value;
                    }}
                  >
                    {presetData.map((preset) => {
                      return (
                        <option key={preset.id} value={preset.url}>
                          {preset.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </InputGroup>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                loggedin ? (
                  <span></span>
                ) : (
                  <Tooltip id="tooltip1">{localString[lang].loginToSP}</Tooltip>
                )
              }
            >
              <div className="screener-title">
                {localString[lang].createNewPreset}

                <InputGroup className="pt-2">
                  <Form.Control
                    disabled={!loggedin}
                    type="text"
                    size="sm"
                    placeholder={localString[lang].presetName}
                    required
                    onChange={(e) => {
                      setPresetName(e.target.value);
                    }}
                  />
                  <Button
                    disabled={!loggedin || !dataExists(presetName)}
                    variant="primary"
                    id="button-addon2"
                    size="sm"
                    onClick={savePresets}
                  >
                    {localString[lang].save}
                  </Button>
                </InputGroup>
              </div>
            </OverlayTrigger>
          </div>
          <div className="d-flex">
            {/* <InputGroup className="mb-3"> */}
            <Button size="sm" href="/screener" className="h-50" variant="info">
              {localString[lang].clearFilters}
            </Button>
            {/* </InputGroup> */}
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap bd-highlight data-container">
          <div className="p-2 bd-highlight flex-column">
            <Row className="d-flex align-item-center mb-2">
              {getTitle("exchange")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={exchange}
                  size="sm"
                  onChange={(e) => {
                    setExchange(e.target.value);
                  }}
                >
                  {scannerOptions["exchange"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("marketCap")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={marketCap}
                  size="sm"
                  onChange={(e) => {
                    setMarketCap(e.target.value);
                  }}
                >
                  {scannerOptions["marketCap"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("performance")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={performance}
                  size="sm"
                  onChange={(e) => {
                    setPerformance(e.target.value);
                  }}
                >
                  {scannerOptions["performance"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("pe")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={pe}
                  size="sm"
                  onChange={(e) => {
                    setPE(e.target.value);
                  }}
                >
                  {scannerOptions["pe"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("pb")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={pb}
                  size="sm"
                  onChange={(e) => {
                    setPB(e.target.value);
                  }}
                >
                  {scannerOptions["pb"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("ps")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={ps}
                  size="sm"
                  onChange={(e) => {
                    setPS(e.target.value);
                  }}
                >
                  {scannerOptions["ps"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("netProfitMargin")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={netProfitMargin}
                  size="sm"
                  onChange={(e) => {
                    setNetProfitMargin(e.target.value);
                  }}
                >
                  {scannerOptions["netProfitMargin"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          </div>
          <div className="p-2 bd-highlight flex-column">
            <Row className="d-flex align-item-center mb-2">
              {getTitle("peg")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={peg}
                  size="sm"
                  onChange={(e) => {
                    setPEG(e.target.value);
                  }}
                >
                  {scannerOptions["peg"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("quick")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={quickRatio}
                  size="sm"
                  onChange={(e) => {
                    setQuickRatio(e.target.value);
                  }}
                >
                  {scannerOptions["quick"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("current")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={currentRatio}
                  size="sm"
                  onChange={(e) => {
                    setCurrentRatio(e.target.value);
                  }}
                >
                  {scannerOptions["current"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("sector")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={sector}
                  size="sm"
                  onChange={(e) => {
                    setSector(e.target.value);
                  }}
                >
                  {scannerOptions["sector"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("industry")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={industry}
                  size="sm"
                  onChange={(e) => {
                    setIndustry(e.target.value);
                  }}
                >
                  {scannerOptions["industry"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("pricecash")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={priceCash}
                  size="sm"
                  onChange={(e) => {
                    setPriceCash(e.target.value);
                  }}
                >
                  {scannerOptions["pricecash"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("operatingMargin")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={operatingMargin}
                  size="sm"
                  onChange={(e) => {
                    setOperatingMargin(e.target.value);
                  }}
                >
                  {scannerOptions["operatingMargin"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          </div>
          <div className="p-2 bd-highlight flex-column">
            <Row className="d-flex align-item-center mb-2">
              {getTitle("peHistoric")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={peHistoric}
                  size="sm"
                  onChange={(e) => {
                    setPeHistoric(e.target.value);
                  }}
                >
                  {scannerOptions["peHistoric"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("pbHistoric")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={pbHistoric}
                  size="sm"
                  onChange={(e) => {
                    setPbHistoric(e.target.value);
                  }}
                >
                  {scannerOptions["pbHistoric"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("psHistoric")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={psHistoric}
                  size="sm"
                  onChange={(e) => {
                    setPsHistoric(e.target.value);
                  }}
                >
                  {scannerOptions["psHistoric"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("pfcf")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={pfcf}
                  size="sm"
                  onChange={(e) => {
                    setPfcf(e.target.value);
                  }}
                >
                  {scannerOptions["pfcf"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("dividendYield")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={dividendYield}
                  size="sm"
                  onChange={(e) => {
                    setDividendYield(e.target.value);
                  }}
                >
                  {scannerOptions["dividendYield"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("payoutRatio")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={payoutRatio}
                  size="sm"
                  onChange={(e) => {
                    setPayoutRatio(e.target.value);
                  }}
                >
                  {scannerOptions["payoutRatio"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          </div>
          <div className="p-2 bd-highlight flex-column">
            <Row className="d-flex align-item-center mb-2">
              {getTitle("deRatio")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={deRatio}
                  size="sm"
                  onChange={(e) => {
                    setDeRatio(e.target.value);
                  }}
                >
                  {scannerOptions["deRatio"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("grossMargin")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  value={grossMargin}
                  size="sm"
                  onChange={(e) => {
                    setGrossMargin(e.target.value);
                  }}
                >
                  {scannerOptions["grossMargin"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("ltdc")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  className="nav-link-hidden"
                  value={ltdc}
                  size="sm"
                  onChange={(e) => {
                    setLtdc(e.target.value);
                  }}
                >
                  {scannerOptions["ltdc"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("revgrowavg")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  aria-label="Lang select"
                  className="nav-link-hidden"
                  value={revenueGrowthHist}
                  size="sm"
                  onChange={(e) => {
                    setRevenueGrowthHist(e.target.value);
                  }}
                >
                  {scannerOptions["revgrowavg"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("revgrow")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  aria-label="Lang select"
                  className="nav-link-hidden"
                  value={revenueGrowth}
                  size="sm"
                  onChange={(e) => {
                    setRevenueGrowth(e.target.value);
                  }}
                >
                  {scannerOptions["revgrow"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="d-flex align-item-center mb-2">
              {getTitle("netincgrow")}
              <Col xs={6} className="data-value text-end data-value-metric">
                <Form.Select
                  aria-label="Lang select"
                  className="nav-link-hidden"
                  value={netIncomeGrowth}
                  size="sm"
                  onChange={(e) => {
                    setNetIncomeGrowth(e.target.value);
                  }}
                >
                  {scannerOptions["netincgrow"].map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {localString[lang]["scanner"][item.name]}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          </div>
        </div>
      </Stack>
      <Stack
        className={`content m-4 ${
          mode === "dark" ? "background-dark" : "background-light"
        }`}
        gap={3}
        id="screener-table"
        ref={myRef}
      >
        <div className="ps-4 pe-4 pt-2">
          <Row>
            <Col xs={6} className="data-value mt-2">
              {/* {localString[lang]["scanner"][type]
                ? localString[lang]["scanner"][type]
                : "TESTING "} */}
              Count: {totalCount}
            </Col>
            <Col xs={6} className="data-value text-end mt-2">
              Last Updated: {updateDate}
            </Col>
          </Row>
        </div>

        <div
          className={`ag-theme-${mode === "dark" ? "quartz-dark" : "quartz"}`}
          style={{ height: "calc(100vh - 6rem)" }}
        >
          <AgGridReact
            // rowData={rowData}
            loading={loading}
            columnDefs={columnDefs}
            autoSizeStrategy={{ type: "fitGridWidth" }}
            rowModelType="infinite"
            pagination={true}
            paginationPageSize={25}
            cacheBlockSize={25}
            onGridReady={onGridReady}
            paginationPageSizeSelector={[25, 50, 100]}
            onSortChanged={onSortChanged}
          />
        </div>
      </Stack>
    </>
  );
};

export default Screener;
