import React, { useEffect, useState } from "react";
import Graph from "../home/Graph";
import { BILLION, COLORS, DURATION } from "../../constants";
import { localString } from "../../localization/localString";
import moment from "moment";

const Eps = ({
  index,
  selectedId,
  setSelectedId,
  duration,
  ticker,
  rawData,
  lang,
  mode,
}) => {
  const [labels, setLabels] = useState([]);
  const [historicalData, setHistoricalData] = useState([]);
  const [titleValue, setTitleValue] = useState(null);
  const [titleChange, setTitleChange] = useState(null);

  useEffect(() => {
    if (ticker !== null && ticker !== "" && ticker?.length !== 0) {
      let dateString = [];
      let data = [];

      for (let i = 0; i < rawData?.length; i++) {
        if (rawData[i]?.eps !== undefined && rawData[i]?.eps !== null) {
          let thisDate =
            duration === DURATION.QUARTERLY
              ? `${rawData[i].period}-${rawData[i].calendarYear}`
              : `${rawData[i].calendarYear}`;
          let dataVal = parseFloat(rawData[i]?.eps);
          dateString.push(thisDate);
          data.push(dataVal);
        }
      }

      let currentIndex = data?.length - 1;
      let firstIndex = currentIndex && currentIndex > 0 ? currentIndex - 1 : 0;

      let currentValue = data.length > 0 ? data[currentIndex] : "";
      let firstValue = data.length > 0 ? data[firstIndex] : "";

      let percentageChange =
        ((parseFloat(currentValue) - parseFloat(firstValue)) /
          Math.abs(parseFloat(firstValue))) *
        100;
      let val = isNaN(parseFloat(currentValue).toFixed(2))
        ? "-"
        : `${parseFloat(currentValue).toFixed(2)}`;
      setTitleValue(val);
      setTitleChange(percentageChange);

      setLabels(dateString);
      setHistoricalData(data);
    }
  }, [ticker, duration, rawData]);

  const yaxisFormatter = (label) => {
    return `${parseFloat(label).toFixed(2)}`;
  };

  const data = () => {
    return {
      labels,
      datasets: [
        {
          data: historicalData,
          borderColor: COLORS.GRAPH.EPS.borderColor,
          backgroundColor: COLORS.GRAPH.EPS.backgroundColor,
        },
      ],
    };
  };

  return (
    <Graph
      index={index}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      chartType={"Bar"}
      data={data}
      description={localString[lang].description.Eps}
      title={`${localString[lang].titles.Eps}`}
      lang={lang}
      yaxisFormatter={yaxisFormatter}
      mode={mode}
      titleValue={titleValue}
      titleChange={titleChange}
      duration={duration}
      subTitle={
        duration === DURATION.QUARTERLY
          ? localString[lang].comparedToLastQuarter
          : localString[lang].comparedToLastYear
      }
    />
  );
};

export default Eps;
