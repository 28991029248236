import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InputGroup from "react-bootstrap/InputGroup";
import StockHoldings from "./StockHoldings";
import compaines from "../../data/companies.json";
import { Helmet } from "react-helmet";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FormControl,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import PortfolioGraph from "./PortfolioGraph";
import OthersPortfolioGraph from "./OthersPortfolioGraph";

const UserPortfolio = ({ lang, mode, user, portfolioId }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [loading, setLoading] = useState(true);
  const myRef = useRef(null);
  const [holdings, setHoldings] = useState([]);
  const [portfolioData, setPortfolioData] = useState(null);
  const [portfolioName, setPortfolioName] = useState([]);
  const [portfolioValue, setPortfolioValue] = useState([]);
  const [portfolioDiff, setPortfolioDiff] = useState([]);
  const [portfolioDiffPct, setPortfolioDiffPct] = useState([]);
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDesc, setMetaDesc] = useState(null);

  const SymbolRender = (p) => {
    if (p.data?.symbol === undefined) return null;

    return `${p.data?.symbol} | ${p.data?.company_name}`;
  };

  const pnlRender = (p) => {
    if (p.data?.p_and_l === undefined) return null;
    if (isNaN(parseFloat(p.data?.p_and_l))) return "-";
    let growth =
      (parseFloat(p.data?.p_and_l) * 100) /
      (parseFloat(p.data?.cost_price) * parseFloat(p.data?.no_of_shares));
    return (
      <div>
        {parseFloat(growth) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(parseFloat(growth).toFixed(2))}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(parseFloat(growth).toFixed(2))}%`}
          </span>
        )}
      </div>
    );
  };
  const CPRender = (p) => {
    if (p.data?.cost_price === undefined) return null;

    return `$${parseFloat(p.data?.cost_price).toFixed(2)}`;
  };
  const SPRender = (p) => {
    if (p.data?.selling_price === undefined) return null;

    return `$${parseFloat(p.data?.selling_price).toFixed(2)}`;
  };

  const fetchHoldings = () => {
    // if (dataExists(user)) {
    let fullUrl = `${apiEndPoints.othersGetStockHoldings}/?id=${portfolioId}`;

    let headers = {
      // "Content-Type": "application/json",
      // Authorization: `Bearer ${user?.token}`,
    };

    WebService.get(fullUrl, {
      headers: headers,
    }).then((response) => {
      if (response?.data) {
        setHoldings(response?.data);
      } else {
        setHoldings([]);
      }
    });
    // }
  };

  const fetchPortfolio = () => {
    // if (dataExists(user)) {
    let fullUrl = `${apiEndPoints.othersPortfolio}/?id=${portfolioId}`;

    let headers = {
      // "Content-Type": "application/json",
      // Authorization: `Bearer ${user?.token}`,
    };

    WebService.get(fullUrl, {
      headers: headers,
    }).then((response) => {
      if (response?.data) {
        let apiData = response?.data;
        let curValue = apiData?.current_value;
        let prevValue = apiData?.prev_value;
        let diff = curValue - prevValue;
        let diffPct = prevValue === 0 ? 100 : (diff * 100) / prevValue;

        setPortfolioData(apiData);
        setPortfolioName(apiData?.name);
        setPortfolioValue(curValue);
        setPortfolioDiff(diff);
        setPortfolioDiffPct(diffPct);
        let title = `${apiData?.name} Simulated Stock Portfolio`;
        let desc = `Explore the top stocks in the simulated portfolio, ${apiData?.name}. Track buy/sell decisions, unrealized profits, and key details like purchase date, P/E ratio, and share price changes to analyze performance.`;
        setMetaTitle(title);
        setMetaDesc(desc);
      } else {
        setHoldings([]);
      }
    });
    // }
  };

  useEffect(() => {
    // if (!dataExists(user)) {
    //   window.location = "/";
    // }
    // setLoggedIn(dataExists(user));
    fetchPortfolio();
    fetchHoldings();
  }, []);

  useEffect(() => {
    if (gridApi) {
      const dataSource = customDataSource();
      gridApi.setGridOption("datasource", dataSource);
      setIsFirstLoad(false);
    }
  }, [gridApi]);

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "symbol",
      headerName: localString[lang]["portfolio"]["symbol"],
      cellRenderer: SymbolRender,
      minWidth: 300,
      sortable: false,
    },
    {
      field: "cost_price",
      headerName: localString[lang]["portfolio"]["purchasePrice"],
      cellRenderer: CPRender,
      minWidth: 120,
      sortable: false,
    },
    {
      field: "date_of_purchase",
      headerName: localString[lang]["portfolio"]["purchaseDate"],
      minWidth: 120,
      sortable: false,
    },
    {
      field: "selling_price",
      headerName: localString[lang]["portfolio"]["priceSold"],
      cellRenderer: SPRender,
      minWidth: 120,
      sortable: false,
    },
    {
      field: "date_of_sale",
      headerName: localString[lang]["portfolio"]["dateSold"],
      minWidth: 120,
      sortable: false,
    },
    {
      field: "no_of_shares",
      headerName: localString[lang]["portfolio"]["noOfShares"],
      minWidth: 80,
      sortable: false,
    },
    {
      field: "p_and_l",
      headerName: localString[lang]["portfolio"]["pctReturns"],
      cellRenderer: pnlRender,
      minWidth: 150,
      sortable: false,
    },
  ]);

  const customDataSource = () => {
    return {
      getRows: (params) => {
        setLoading(true);
        const { startRow, endRow } = params;
        const pageSize = endRow - startRow; // Page size
        const pageNumber = startRow / pageSize + 1; // Calculate page number

        // if (dataExists(user)) {
        let headers = {
          // "Content-Type": "application/json",
          // Authorization: `Bearer ${user?.token}`,
        };

        let fullUrl = `${apiEndPoints.othersTransactions}/?page=${pageNumber}&page_size=${pageSize}&id=${portfolioId}`;
        WebService.get(fullUrl, { headers: headers })
          .then((response) => {
            let data = response?.data;
            params.successCallback(data?.results, data?.count);
            setLoading(false);
            // setTotalCount(data?.count);
            // setUpdateDate(updateDate.toDateString());
          })
          .catch((error) => {
            params.failCallback();
            console.error("Error fetching data:", error);
            setLoading(false);
          });
        // }
      },
    };
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>

      <Stack
        className={`content screener ${
          mode === "dark" ? "background-dark" : "background-light"
        }`}
        gap={3}
      >
        <div className="ps-4 pe-4 pt-2">
          <Row>
            {portfolioData && (
              <Col className="mt-2">
                <div>
                  <div className="mb-3">
                    <span className="h4">{`${portfolioName} Portfolio`}</span>{" "}
                  </div>
                  <p className="m-0">
                    <span className="h3">
                      $
                      {parseFloat(
                        parseFloat(portfolioValue).toFixed(3)
                      ).toLocaleString()}
                    </span>
                    <span className="ps-2">
                      {parseFloat(portfolioDiffPct) > 0 ? (
                        <span className="chart-numbers-change-green">
                          <i className="bi bi-arrow-up-short"></i>
                          {`${Math.abs(
                            parseFloat(portfolioDiffPct).toFixed(2)
                          )}%`}
                        </span>
                      ) : (
                        <span className="chart-numbers-change-red">
                          <i className="bi bi-arrow-down-short"></i>
                          {`${Math.abs(
                            parseFloat(portfolioDiffPct).toFixed(2)
                          )}%`}
                        </span>
                      )}
                    </span>
                  </p>
                  <p>
                    <span className="">
                      {parseFloat(portfolioDiff) > 0 ? (
                        <span className="chart-numbers-change-green">
                          <i className="bi bi-arrow-up-short"></i>
                          {`$${Math.abs(
                            parseFloat(portfolioDiff).toFixed(2)
                          ).toLocaleString()}`}
                        </span>
                      ) : (
                        <span className="chart-numbers-change-red">
                          <i className="bi bi-arrow-down-short"></i>
                          {`$${Math.abs(
                            parseFloat(portfolioDiff).toFixed(2)
                          ).toLocaleString()}`}
                        </span>
                      )}
                    </span>
                  </p>
                </div>
              </Col>
            )}
          </Row>
        </div>
        <Container fluid>
          <Row>
            {/* Left section: 66% width for the graph */}
            <Col className="pb-4">
              <OthersPortfolioGraph
                mode={mode}
                user={user}
                portfolioId={portfolioId}
                lang={lang}
              />
            </Col>
          </Row>
        </Container>
      </Stack>

      <StockHoldings
        mode={mode}
        user={user}
        holdings={holdings}
        portfolioValue={portfolioValue}
        lang={lang}
        allowSell={false}
      />
      <Stack
        className={`content m-4 ${
          mode === "dark" ? "background-dark" : "background-light"
        }`}
        gap={3}
        id="screener-table"
        ref={myRef}
      >
        <div className="ps-4 pe-4 pt-2">
          <Row>
            <Col xs={6} className="mt-2">
              {localString[lang]["portfolio"]["sellTransactions"]}
            </Col>
          </Row>
        </div>

        <div
          className={`ag-theme-${mode === "dark" ? "quartz-dark" : "quartz"}`}
          style={{ height: "300px" }}
        >
          <AgGridReact
            // rowData={rowData}
            loading={loading}
            columnDefs={columnDefs}
            autoSizeStrategy={{ type: "fitGridWidth" }}
            rowModelType="infinite"
            pagination={true}
            paginationPageSize={25}
            cacheBlockSize={25}
            onGridReady={onGridReady}
            paginationPageSizeSelector={[25, 50, 100]}
            // onSortChanged={onSortChanged}
          />
        </div>
      </Stack>
    </>
  );
};

export default UserPortfolio;
