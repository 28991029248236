import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import { setAccessTokenToHeader } from "../../services/helper";
import "./home.scss";
import { localString } from "../../localization/localString";

const Login = (props) => {
  const [loginError, setLoginError] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [otp, setOTP] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [activeForm, setActiveForm] = useState("login");
  const [lang, setLang] = useState(props.lang);
  const [loading, setLoading] = useState(false);

  // Function to handle email/password login
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);

    let fullUrl = `${apiEndPoints.login}/`;
    let payload = {
      email: email,
      password: password,
    };

    try {
      let response = await WebService.post(fullUrl, payload);
      if (response?.status >= 200 && response?.status < 300) {
        let curData = response?.data;
        const { access, refresh, first_name, last_name } = curData;

        // Save tokens to localStorage or sessionStorage
        setAccessTokenToHeader(access);
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        let userJson = {
          first_name: first_name,
          last_name: last_name,
          token: access,
        };
        localStorage.setItem("user", JSON.stringify(userJson));
        setLoading(false);
        window.location.reload();
      } else {
        throw response;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setLoginError("Invalid email or password");
    }
  };

  // Function to handle email/password login
  const handleSignup = async (e) => {
    setLoading(true);
    e.preventDefault(); // Prevent default form submission

    let fullUrl = `${apiEndPoints.register}/`;
    let payload = {
      email: email,
      password: password,
      first_name: firstName,
      last_name: lastName,
    };
    try {
      let response = await WebService.post(fullUrl, payload);
      if (response?.status >= 200 && response?.status < 300) {
        let curData = response?.data;
        const { access, refresh } = curData;

        // Save tokens to localStorage or sessionStorage
        setAccessTokenToHeader(access);
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        let userJson = {
          first_name: firstName,
          last_name: lastName,
          token: access,
        };
        localStorage.setItem("user", JSON.stringify(userJson));
        setLoading(false);
        window.location.reload();
      } else {
        throw response;
      }
    } catch (error) {
      setLoading(false);
      setLoginError(error?.response?.data["email"]);
    }
  };
  // Function to handle email/password login
  const handleForgotPassword = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);

    let fullUrl = `${apiEndPoints.resetPasswordOtp}/`;
    let payload = {
      email: email,
    };

    try {
      let response = await WebService.post(fullUrl, payload);
      if (response?.status >= 200 && response?.status < 300) {
        setActiveForm("resetpassword");
        setLoading(false);
      } else {
        throw response;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setLoginError(error?.response?.data["error"]);
    }
  };
  // Function to handle email/password login
  const handleResetPassword = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);

    let fullUrl = `${apiEndPoints.resetPassword}/`;
    let payload = {
      email: email,
      new_password: password,
      otp: otp,
    };

    try {
      let response = await WebService.post(fullUrl, payload);
      if (response?.status >= 200 && response?.status < 300) {
        setActiveForm("login");
        setLoading(false);
      } else {
        throw response;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setLoginError(error?.response?.data["error"]);
    }
  };

  // Function to handle Google login success
  const handleGoogleLoginSuccess = async (response) => {
    const token = response.access_token;
    setLoading(true);
    let fullUrl = `${apiEndPoints.socialLogin}/`;
    let payload = {
      token: token,
    };
    try {
      let response = await WebService.post(fullUrl, payload);
      if (response?.status >= 200 && response?.status < 300) {
        let curData = response?.data;
        const { access, refresh, first_name, last_name } = curData;

        // Save tokens to localStorage or sessionStorage
        setAccessTokenToHeader(access);
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        let userJson = {
          first_name: first_name,
          last_name: last_name,
          token: access,
        };
        localStorage.setItem("user", JSON.stringify(userJson));
        setLoading(false);
        window.location.reload();
      } else {
        throw response;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setLoginError("Invalid email or password");
    }
  };

  // Function to handle Google login failure
  const handleGoogleLoginFailure = (error) => {
    console.error("Google login error:", error);
    setLoginError("Google login failed");
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleGoogleLoginSuccess(tokenResponse),
  });

  return (
    <Modal
      {...props}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {localString[lang]["login"][activeForm]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-auto">
        <div className="login-container">
          {/* Login Form */}
          <form
            onSubmit={handleLogin}
            className={`${activeForm === "login" ? "" : "d-none"}`}
          >
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text rounded-end-0"
                  id="login-email"
                >
                  {localString[lang]["login"]["email"]}
                </span>
              </div>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control"
                placeholder={localString[lang]["login"]["email"]}
                aria-label={localString[lang]["login"]["email"]}
                aria-describedby="login-email"
                required
              />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text rounded-end-0"
                  id="login-password"
                >
                  {localString[lang]["login"]["password"]}
                </span>
              </div>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="form-control"
                placeholder={localString[lang]["login"]["password"]}
                aria-label={localString[lang]["login"]["password"]}
                aria-describedby="login-password"
                required
              />
            </div>
            {loginError && <p>{loginError}</p>}
            <div className="text-center">
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary px-5"
              >
                {localString[lang]["login"]["login"]}
              </button>
            </div>
            <div className="text-center">
              <p
                className="btn btn-link m-0"
                onClick={() => setActiveForm("forgotpassword")}
              >
                {localString[lang]["login"]["forgotPasswordQ"]}
              </p>
            </div>
            <div className="text-center">
              <p
                className="btn btn-link m-0"
                onClick={() => setActiveForm("signup")}
              >
                {localString[lang]["login"]["createAccount"]}
              </p>
            </div>
          </form>
          {/* Sign up Form */}
          <form
            onSubmit={handleSignup}
            className={`${activeForm === "signup" ? "" : "d-none"}`}
          >
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text rounded-end-0"
                  id="signup-email"
                >
                  {localString[lang]["login"]["email"]}
                </span>
              </div>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control"
                placeholder={localString[lang]["login"]["email"]}
                aria-label={localString[lang]["login"]["email"]}
                aria-describedby="signup-email"
                required
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text rounded-end-0"
                  id="signup-firstname"
                >
                  {localString[lang]["login"]["firstName"]}
                </span>
              </div>
              <input
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                className="form-control"
                placeholder={localString[lang]["login"]["firstName"]}
                aria-label={localString[lang]["login"]["firstName"]}
                aria-describedby="signup-firstname"
                required
              />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text rounded-end-0"
                  id="signup-lastname"
                >
                  {localString[lang]["login"]["lastName"]}
                </span>
              </div>
              <input
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                className="form-control"
                placeholder={localString[lang]["login"]["lastName"]}
                aria-label={localString[lang]["login"]["lastName"]}
                aria-describedby="signup-lastname"
                required
              />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text rounded-end-0"
                  id="signup-password"
                >
                  {localString[lang]["login"]["password"]}
                </span>
              </div>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="form-control"
                placeholder={localString[lang]["login"]["password"]}
                aria-label={localString[lang]["login"]["password"]}
                aria-describedby="signup-password"
                required
              />
            </div>
            {loginError && <p>{loginError}</p>}
            <div className="text-lg-center">
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary px-5"
              >
                {localString[lang]["login"]["signup"]}
              </button>
            </div>
            <div className="text-lg-center">
              <p
                className="btn btn-link m-0"
                onClick={() => setActiveForm("login")}
              >
                {localString[lang]["login"]["login"]}
              </p>
            </div>
          </form>

          {/* Forgot Password */}
          <form
            onSubmit={handleForgotPassword}
            className={`${activeForm === "forgotpassword" ? "" : "d-none"}`}
          >
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text rounded-end-0"
                  id="forgotpassword-email"
                >
                  {localString[lang]["login"]["email"]}
                </span>
              </div>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control"
                placeholder={localString[lang]["login"]["email"]}
                aria-label={localString[lang]["login"]["email"]}
                aria-describedby="forgotpassword-email"
                required
              />
            </div>
            {loginError && <p>{loginError}</p>}
            <div className="text-lg-center">
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary px-5"
              >
                {localString[lang]["login"]["requestOTP"]}
              </button>
            </div>
            <div className="text-lg-center">
              <p
                className="btn btn-link m-0"
                onClick={() => setActiveForm("login")}
              >
                {localString[lang]["login"]["login"]}
              </p>
            </div>
          </form>
          {/* Reset Password Form */}
          <form
            onSubmit={handleResetPassword}
            className={`${activeForm === "resetpassword" ? "" : "d-none"}`}
          >
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text rounded-end-0"
                  id="resetpassword-email"
                >
                  {localString[lang]["login"]["email"]}
                </span>
              </div>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control"
                placeholder={localString[lang]["login"]["email"]}
                aria-label={localString[lang]["login"]["email"]}
                aria-describedby="resetpassword-email"
                required
              />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text rounded-end-0"
                  id="resetpassword-otp"
                >
                  {localString[lang]["login"]["otp"]}
                </span>
              </div>
              <input
                onChange={(e) => setOTP(e.target.value)}
                type="password"
                className="form-control"
                placeholder={localString[lang]["login"]["otp"]}
                aria-label={localString[lang]["login"]["otp"]}
                aria-describedby="resetpassword-otp"
                required
              />
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text rounded-end-0"
                  id="resetpassword-password"
                >
                  {localString[lang]["login"]["password"]}
                </span>
              </div>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="form-control"
                placeholder={localString[lang]["login"]["password"]}
                aria-label={localString[lang]["login"]["password"]}
                aria-describedby="resetpassword-password"
                required
              />
            </div>

            {loginError && <p>{loginError}</p>}
            <div className="text-lg-center">
              <button
                disabled={loading}
                type="submit"
                onClick={() => setActiveForm("resetpassword")}
                className="btn btn-primary px-5"
              >
                {localString[lang]["login"]["resetpassword"]}
              </button>
            </div>
            <div className="text-lg-center">
              <p
                className="btn btn-link m-0"
                onClick={() => setActiveForm("login")}
              >
                {localString[lang]["login"]["login"]}
              </p>
            </div>
          </form>
          {/* <hr /> */}
          {/* Google Login */}
          {/* <div className="align-google"> */}
          {/* <Button onClick={() => login()}>
              Sign in with Google 🚀
            </Button> */}
          {/* <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginFailure}
              text={`${activeForm === "signup" ? 'signup_with' : 'signin_with'}`}
            /> */}
          {/* </div> */}
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default Login;
