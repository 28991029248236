import React, { useState, useEffect } from "react";
import { Accordion, Row, Col, Container, Table, Button } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { dataExists, nFormatter } from "../../helpers";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import Modal from "react-bootstrap/Modal";
import SellModal from "./SellModal";
import { localString } from "../../localization/localString";

const StockHoldings = ({
  mode,
  user,
  forceReload,
  holdings,
  allowSell = true,
  portfolioValue,
  lang,
}) => {
  const [showSellModal, setShowSellModal] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);

  // Handler for when the sell button is clicked
  const handleSell = (transaction) => {
    setCurrentTransaction(transaction);
    setShowSellModal(true);
  };

  return (
    <Stack
      className={`content  ${
        mode === "dark" ? "background-dark" : "background-light"
      }`}
      gap={3}
    >
      <div className="p-4 bd-highlight flex-fill">
        {/* Header row to display column titles */}
        <Row
          className="font-weight-bold py-2"
          style={{ width: "99%", paddingLeft: "15px" }}
        >
          <Col>{localString[lang]["portfolio"]["company"]}</Col>
          <Col className="hide-mobile">
            {localString[lang]["portfolio"]["sector"]}
          </Col>
          <Col className="hide-mobile">
            {localString[lang]["portfolio"]["currentPrice"]}
          </Col>
          <Col className="hide-mobile">
            {localString[lang]["portfolio"]["avgPurchasePrice"]}
          </Col>
          <Col className="hide-mobile">
            {localString[lang]["portfolio"]["totalShares"]}
          </Col>
          <Col className="hide-mobile">
            {localString[lang]["portfolio"]["profitAndLoss"]}
          </Col>
          <Col className="hide-mobile">
            {localString[lang]["portfolio"]["portfolioPct"]}
          </Col>
        </Row>

        <Accordion>
          {holdings?.map((holding, index) => {
            let currentValue =
              parseFloat(holding?.current_price) *
              parseFloat(holding?.no_of_shares);
            let costValue =
              parseFloat(holding?.average_cost_price) *
              parseFloat(holding?.no_of_shares);
            let pnl = currentValue - costValue;
            let pnlPct = costValue === 0 ? 100 : (pnl * 100) / costValue;
            let pct = portfolioValue
              ? (currentValue * 100) / portfolioValue
              : "";

            return (
              <Accordion.Item eventKey={index.toString()} key={holding.symbol}>
                <Accordion.Header>
                  <Row style={{ width: "100%" }} className="data-value">
                    <Col>{`${holding?.symbol} | ${holding?.company_name}`}</Col>
                    <Col className="hide-mobile">{`${holding?.sector}`}</Col>
                    <Col className="hide-mobile">{`$${parseFloat(
                      holding?.current_price
                    ).toFixed(2)}`}</Col>
                    <Col className="hide-mobile">{`$${parseFloat(
                      holding?.average_cost_price
                    ).toFixed(2)}`}</Col>
                    <Col className="hide-mobile">{holding?.no_of_shares}</Col>
                    <Col className="hide-mobile">
                      {parseFloat(pnl) > 0 ? (
                        <span className="chart-numbers-change-green">
                          <i className="bi bi-arrow-up-short"></i>
                          {`$${Math.abs(parseFloat(pnl)).toFixed(
                            2
                          )} | ${Math.abs(parseFloat(pnlPct)).toFixed(2)}%`}
                        </span>
                      ) : (
                        <span className="chart-numbers-change-red">
                          <i className="bi bi-arrow-down-short"></i>
                          {`$${Math.abs(parseFloat(pnl)).toFixed(
                            2
                          )} | ${Math.abs(parseFloat(pnlPct)).toFixed(2)}%`}
                        </span>
                      )}
                    </Col>
                    <Col className="hide-mobile">{`${parseFloat(pct).toFixed(
                      2
                    )}%`}</Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body className="scroll-div">
                  <Table striped bordered hover className="data-value">
                    <thead>
                      <tr>
                        <th>{localString[lang]["portfolio"]["date"]}</th>
                        <th>
                          {localString[lang]["portfolio"]["purchasePrice"]}
                        </th>
                        <th>{localString[lang]["portfolio"]["noOfShares"]}</th>
                        <th>{localString[lang]["portfolio"]["capital"]}</th>
                        <th>
                          {localString[lang]["portfolio"]["profitAndLoss"]}
                        </th>
                        {allowSell && <th></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {holding?.components?.map((transaction) => {
                        let componentCurrentValue =
                          parseFloat(transaction?.current_price) *
                          parseFloat(transaction?.no_of_shares);
                        let componentcostValue =
                          parseFloat(transaction?.cost_price) *
                          parseFloat(transaction?.no_of_shares);
                        let componentPnl =
                          componentCurrentValue - componentcostValue;

                        let componentPnlPct =
                          componentcostValue === 0
                            ? 100
                            : (componentPnl * 100) / componentcostValue;

                        return (
                          <tr key={transaction?.id}>
                            <td>{transaction?.date_of_purchase}</td>
                            <td>
                              {`$${parseFloat(transaction?.cost_price).toFixed(
                                2
                              )}`}
                            </td>
                            <td>{`${parseInt(transaction?.no_of_shares)}`}</td>
                            <td>
                              {`$${parseFloat(componentcostValue).toFixed(2)}`}
                            </td>
                            <td>
                              {parseFloat(componentPnl) > 0 ? (
                                <span className="chart-numbers-change-green">
                                  <i className="bi bi-arrow-up-short"></i>
                                  {`$${Math.abs(
                                    parseFloat(componentPnl)
                                  ).toFixed(2)} | ${Math.abs(
                                    parseFloat(componentPnlPct)
                                  ).toFixed(2)}%`}
                                </span>
                              ) : (
                                <span className="chart-numbers-change-red">
                                  <i className="bi bi-arrow-down-short"></i>
                                  {`$${Math.abs(
                                    parseFloat(componentPnl)
                                  ).toFixed(2)} | ${Math.abs(
                                    parseFloat(componentPnlPct)
                                  ).toFixed(2)}%`}
                                </span>
                              )}
                            </td>
                            {allowSell && (
                              <td>
                                <Button
                                  variant="primary"
                                  onClick={() => handleSell(transaction)}
                                  size="sm"
                                >
                                  {localString[lang]["portfolio"]["sell"]}
                                </Button>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
      <SellModal
        show={showSellModal}
        onHide={() => setShowSellModal(false)}
        transaction={currentTransaction}
        user={user}
      />
    </Stack>
  );
};

export default StockHoldings;
