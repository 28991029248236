import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Home from "../components/home/Home";
import { THEMES } from "../constants";
import HomeTabs from "../components/home/HomeTabs";
import ScrollToTop from "../components/home/ScrollToTop";
import compaines from "../data/companies.json";
import Share from "../components/home/Share";
import axios from "axios";
import SPSidebar from "../components/header/Sidebar";
import Stack from "react-bootstrap/Stack";

const PrivacyPolicy = ({ user }) => {
  const [allTickers, setAllTickers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(null);
  const [starredTickers, setStarredTickers] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(localStorage.getItem("isDarkMode"))
  );
  const [lang, setLang] = useState(
    JSON.parse(localStorage.getItem("language")) || "en"
  );

  useEffect(() => {
    if (allTickers === null) {
      let fullUrl = `/assets/data/companies.json`;

      axios.get(fullUrl).then((response) => {
        setAllTickers(response.data);
        // console.log(response.data);
      });
      // setAllTickers(compaines);
    }
    let lsIsDarkMode = JSON.parse(localStorage.getItem("isDarkMode"));
    let isDarkMode = lsIsDarkMode === null ? true : lsIsDarkMode;
    setIsDarkMode(isDarkMode);
    setStarredTickers(JSON.parse(localStorage.getItem("starredTickers")));
    setLoading(false);
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const updateStarred = (item) => {
    let starredTickersList = starredTickers || [];
    let itemIndex = starredTickers?.findIndex((o) => o.ticker === item.ticker);

    if (itemIndex === -1 || itemIndex === undefined) {
      starredTickersList.push(item);
    } else {
      starredTickersList.splice(itemIndex, 1);
    }
    setStarredTickers(JSON.parse(JSON.stringify(starredTickersList)));
    // starredTickersList);
    localStorage.setItem("starredTickers", JSON.stringify(starredTickersList));
  };

  useEffect(() => {
    localStorage.setItem("isDarkMode", isDarkMode);
    const htmlElement = document.querySelector("html");
    htmlElement.setAttribute(
      "data-bs-theme",
      isDarkMode ? THEMES.DARK : THEMES.LIGHT
    );
    setMode(isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <>
      {loading ? (
        <> </>
      ) : (
        <>
          <Header
            toggleDarkMode={toggleDarkMode}
            lang={lang}
            setLang={setLang}
            mode={mode}
            starredTickers={starredTickers}
            user={user}
          />
          <SPSidebar mode={mode} lang={lang} user={user} />
          <section className="section" id="stock-penguins-section">
            <Stack
              className={`content ${
                mode === "dark" ? "background-dark" : "background-light"
              }`}
              gap={3}
            >
              <div className="container mt-5">
                <div className="mb-4">
                  <h1>Privacy Policy</h1>
                  <p>
                    <strong>Effective Date:</strong> 1 Octobet, 2024
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Introduction</h3>
                  <p>
                    StockPenguins.com ("we," "us," or "our") respects the
                    privacy of our users ("you" or "your"). This Privacy Policy
                    describes the types of information we collect from and about
                    users who access our mobile application ("App"), and how we
                    use that information.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Information We Collect</h3>
                  <p>We collect the following types of information:</p>
                  <ul>
                    <li>
                      <strong>Registration Information:</strong> Username, email
                      address, and password.
                    </li>
                    <li>
                      <strong>Usage Information:</strong> Stocks you screen,
                      search history, and features accessed.
                    </li>
                    <li>
                      <strong>Device Information:</strong> Device type,
                      operating system, and IP address.
                    </li>
                  </ul>
                </div>

                <div className="mb-4">
                  <h3>How We Use Your Information</h3>
                  <p>We use the information we collect to:</p>
                  <ul>
                    <li>
                      Provide and improve the App and its functionalities.
                    </li>
                    <li>Personalize your experience with the App.</li>
                    <li>
                      Communicate with you about the App, such as sending
                      important updates and announcements.
                    </li>
                    <li>Analyze trends and usage patterns.</li>
                  </ul>
                </div>

                <div className="mb-4">
                  <h3>Data Sharing</h3>
                  <p>
                    We will not share your personal information with third
                    parties without your consent, except in the following
                    limited circumstances:
                  </p>
                  <ul>
                    <li>
                      To comply with legal requirements or respond to legal
                      process.
                    </li>
                    <li>To protect our rights and property.</li>
                    <li>To prevent fraud or other harmful activities.</li>
                  </ul>
                </div>

                <div className="mb-4">
                  <h3>Your Choices</h3>
                  <p>
                    You can access and update your personal information through
                    your account settings. You can also opt out of receiving
                    marketing communications from us.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Data Security</h3>
                  <p>
                    We take reasonable steps to protect your information from
                    unauthorized access, disclosure, alteration, or destruction.
                    However, no internet transmission is ever completely secure.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Changes to this Privacy Policy</h3>
                  <p>
                    We may update this Privacy Policy from time to time and post
                    any changes on this page.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Contact Us</h3>
                  <p>
                    If you have any questions, please contact us at
                    stockpenguins@gmail.com.
                  </p>
                </div>
              </div>
            </Stack>
          </section>
        </>
      )}
      <Share />
      <ScrollToTop />
    </>
  );
};

export default PrivacyPolicy;
