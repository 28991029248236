import React, { useEffect, useState } from "react";
import Graph from "../home/Graph";
import { BILLION, COLORS, DURATION } from "../../constants";
import { localString } from "../../localization/localString";
import moment from "moment";

const RevenueNetIncomeRatio = ({
  index,
  selectedId,
  setSelectedId,
  duration,
  ticker,
  rawData,
  lang,
  mode,
  currency,
}) => {
  const [labels, setLabels] = useState([]);
  const [historicalData1, setHistoricalData1] = useState([]);
  const [historicalData2, setHistoricalData2] = useState([]);
  const [titleValue, setTitleValue] = useState(null);
  const [titleChange, setTitleChange] = useState(null);
  const [titleValue2, setTitleValue2] = useState(null);
  const [titleChange2, setTitleChange2] = useState(null);

  useEffect(() => {
    if (ticker !== null && ticker !== "" && ticker?.length !== 0) {
      let dateString = [];
      let data1 = [];
      let data2 = [];

      for (let i = 0; i < rawData.length; i++) {
        if (
          rawData[i].revenue !== undefined &&
          rawData[i].revenue !== null &&
          rawData[i].netIncome !== undefined &&
          rawData[i].netIncome !== null
        ) {
          let thisDate =
            duration === DURATION.QUARTERLY
              ? `${rawData[i].period}-${rawData[i].calendarYear}`
              : `${rawData[i].calendarYear}`;
          let dataVal1 = parseFloat(rawData[i].revenue) / BILLION;
          let dataVal2 = parseFloat(rawData[i].netIncome) / BILLION;
          dateString.push(thisDate);
          data1.push(dataVal1.toFixed(3));
          data2.push(dataVal2.toFixed(3));
        }
      }

      let currentIndex = data1?.length - 1;
      let firstIndex = currentIndex && currentIndex > 0 ? currentIndex - 1 : 0;
      let currentValue = data1.length > 0 ? data1[currentIndex] : "";
      let firstValue = data1.length > 0 ? data1[firstIndex] : "";

      let percentageChange =
        ((parseFloat(currentValue) - parseFloat(firstValue)) /
          Math.abs(parseFloat(firstValue))) *
        100;
      let val = isNaN(parseFloat(currentValue).toFixed(2))
        ? "-"
        : `${currency}${parseFloat(currentValue).toFixed(2)}B`;
      setTitleValue(val);
      setTitleChange(percentageChange);

      let currentIndex2 = data2?.length - 1;
      let firstIndex2 =
        currentIndex2 && currentIndex2 > 0 ? currentIndex2 - 1 : 0;

      let currentValue2 = data2.length > 0 ? data2[currentIndex2] : "";
      let firstValue2 = data2.length > 0 ? data2[firstIndex2] : "";

      let percentageChange2 =
        ((parseFloat(currentValue2) - parseFloat(firstValue2)) /
          Math.abs(parseFloat(firstValue2))) *
        100;
      setTitleValue2(`${currency}${parseFloat(currentValue2).toFixed(2)}B`);
      setTitleChange2(percentageChange2);

      setLabels(dateString);
      setHistoricalData1(data1);
      setHistoricalData2(data2);
    }
  }, [ticker, duration, rawData]);

  const tooltipFormatter = (tooltipItem) => {
    return `${tooltipItem.dataset.label}: ${currency}${tooltipItem.formattedValue}B`;
  };

  const yaxisFormatter = (label) => {
    return `${parseFloat(label).toFixed(2)}B`;
  };

  const data = () => {
    return {
      labels,
      datasets: [
        {
          label: localString[lang].labels.Revenue,
          fill: false,
          data: historicalData1,
          borderColor: COLORS.GRAPH.REVENUE_VS_NET_INCOME.borderColor,
          backgroundColor: COLORS.GRAPH.REVENUE_VS_NET_INCOME.backgroundColor,
        },
        {
          label: localString[lang].labels.NetIncome,
          fill: false,
          data: historicalData2,
          borderColor: COLORS.GRAPH.TOTAL_CASH.borderColor,
          backgroundColor: COLORS.GRAPH.TOTAL_CASH.backgroundColor,
        },
      ],
    };
  };

  return (
    <Graph
      index={index}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      chartType={"Bar"}
      data={data}
      description={localString[lang].description.RevenueVsNetIncome}
      title={`${localString[lang].titles.RevenueVsNetIncome}`}
      lang={lang}
      tooltipFormatter={tooltipFormatter}
      yaxisFormatter={yaxisFormatter}
      mode={mode}
      titleValue={titleValue}
      titleChange={titleChange}
      titleValue2={titleValue2}
      titleChange2={titleChange2}
      duration={duration}
      subTitle={
        duration === DURATION.QUARTERLY
          ? localString[lang].comparedToLastQuarter
          : localString[lang].comparedToLastYear
      }
    />
  );
};

export default RevenueNetIncomeRatio;
