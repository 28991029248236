import React, { useEffect, useState } from "react";
import Graph from "../home/Graph";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import moment from "moment";
import { COLORS, DURATION, LANG } from "../../constants";
import { localString } from "../../localization/localString";

const Price = ({
  index,
  selectedId,
  setSelectedId,
  duration,
  ticker,
  lang,
  mode,
  threeMonthGrowth,
  oneYearGrowth,
  yearlyChange
}) => {
  const [labels, setLabels] = useState([]);
  const [historicalData, setHistoricalData] = useState([]);
  const [titleValue, setTitleValue] = useState(null);
  const [titleChange, setTitleChange] = useState(null);

  useEffect(() => {
    if (ticker !== null && ticker !== "" && ticker?.length !== 0) {
      let fullUrl = `${apiEndPoints.getHistoricalPrice}/${ticker}/`;
      WebService.get(fullUrl).then((response) => {
        let data = response?.data;

        let dateString = [];
        let priceHistory = [];
        for (let i = 0; i < data?.length; i++) {
          let timestamp = data[i]['date'];
          let dateFormat =
            duration === DURATION.QUARTERLY ? "[Q]Q-YYYY" : "YYYY";
          let thisDate = moment(timestamp).format(dateFormat);
          dateString.push(thisDate);
          priceHistory.push(data[i]['close']);
        }
        let currentValue =
          priceHistory?.length > 0 ? priceHistory[priceHistory.length - 1] : "";

        let percentageChange =
          duration === DURATION.QUARTERLY ? threeMonthGrowth : oneYearGrowth;
        let val = isNaN(parseFloat(currentValue).toFixed(2))
          ? "-"
          : `$${parseFloat(currentValue).toFixed(2)}`;

        setTitleValue(val);
        setTitleChange(percentageChange);

        setLabels(dateString);
        setHistoricalData(priceHistory);
      });
    }
  }, [ticker, duration]);

  const tooltipFormatter = (tooltipItem) => {
    return `$${tooltipItem.formattedValue}`;
  };
  const yaxisFormatter = (label) => {
    return `$${parseFloat(label).toFixed(2)}`;
  };

  const data = () => {
    return {
      labels,
      datasets: [
        {
          fill: true,
          data: historicalData,
          borderColor: COLORS.GRAPH.PRICE.borderColor,
          backgroundColor: COLORS.GRAPH.PRICE.backgroundColor,
        },
      ],
    };
  };

  return (
    <Graph
      index={index}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      chartType={"Bar"}
      data={data}
      description={localString[lang].description.Price}
      title={`${localString[lang].titles.Price}`}
      lang={lang}
      tooltipFormatter={tooltipFormatter}
      yaxisFormatter={yaxisFormatter}
      mode={mode}
      titleValue={titleValue}
      titleChange={titleChange}
      yearlyChange={yearlyChange}
      duration={duration}      
      subTitle={
        duration === DURATION.QUARTERLY
          ? localString[lang].comparedToLastQuarter
          : localString[lang].comparedToLastYear
      }
    />
  );
};

export default Price;
