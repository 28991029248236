import React, { useCallback, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip as Tip,
  Filler,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Col from "react-bootstrap/Col";
import { localString } from "../../localization/localString";
import { THEME_COLORS } from "../../colors";
import { COLORS, DURATION } from "../../constants";
import { Button } from "react-bootstrap";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import { dataExists } from "../../helpers";

const OthersPortfolioGraph = ({
  index,
  title,
  lang,
  tooltipFormatter,
  yaxisFormatter,
  mode,
  user,
  portfolioId,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tip,
    Filler,
    Legend
  );

  const [label, setLabel] = useState([]);
  const [data, setData] = useState([]);
  const [duration, setDuration] = useState("7D");

  useEffect(() => {
    fetchHistory();
  }, []);

  useEffect(() => {
    fetchHistory();
  }, [duration]);

  const fetchHistory = () => {
    // if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.othersPortfolioHistory}/?duration=${duration}&id=${portfolioId}`;

      let headers = {
        // "Content-Type": "application/json",
        // Authorization: `Bearer ${user?.token}`,
      };

      WebService.get(fullUrl, {
        headers: headers,
      }).then((response) => {
        if (response?.data) {
          let historyDataObj = response?.data;
          let __data = [];
          let __label = [];
          for (let i = 0; i < historyDataObj.length; i++) {
            __data.push(historyDataObj[i]["value"]);
            __label.push(historyDataObj[i]["date"]);
          }
          setData(__data);
          setLabel(__label);
        } else {
          setData([]);
          setLabel([]);
        }
      });
    // }
  };

  const graphData = {
    labels: label,
    datasets: [
      {
        data: data,
        borderColor: COLORS.GRAPH.PRICE.borderColor,
        // backgroundColor: [
        //   'rgba(255, 255, 255, 1)',
        //   'rgba(255, 255, 255, 1)',
        //   'rgba(255, 255, 255, 1)',
        // ],
        borderWidth: 1,
      },
    ],
  };

  const options = (type) => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      scales: {
        y: {
          ticks: {
            callback: function (label, index, labels) {
              return `$${parseFloat(label).toLocaleString()}`;
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          // align: 'start',
          color: THEME_COLORS[mode].graph.title,
          text: `${title}`,
          font: {
            size: 18,
          },
        },
        tooltip: {
          titleFont: {
            size: 15,
          },
          callbacks: {
            label: function (tooltipItem, data) {
              if (tooltipFormatter) {
                return tooltipFormatter(tooltipItem);
              }
              return tooltipItem.formattedValue;
            },
          },
        },
      },
    };
  };

  return (
    <>
      <div
        style={{
          // height: "400px",
          width: "100%",
          margin: "0 auto",
        }}
        className="px-3"
      >
        <div className="d-flex justify-content-between mb-2">
          <OverlayTrigger placement="left" overlay={<></>}>
            <div>
              <div>{title}</div>
              <div className="d-flex align-items-center">
                <span className="">{localString[lang]["portfolio"]["history"]}</span>
              </div>
            </div>
          </OverlayTrigger>
          <div>
            <Button
              className="me-2 portfolio-chart-button"
              variant="secondary"
              onClick={() => setDuration("7D")}
              size="sm"
            >
              7D
            </Button>
            <Button
              className="me-2 portfolio-chart-button"
              variant="secondary"
              onClick={() => setDuration("30D")}
              size="sm"
            >
              30D
            </Button>
            <Button
              className="me-2 portfolio-chart-button"
              variant="secondary"
              onClick={() => setDuration("300D")}
              size="sm"
            >
              300D
            </Button>
            <Button
              className="me-2 portfolio-chart-button"
              variant="secondary"
              onClick={() => setDuration("all")}
              size="sm"
            >
              All
            </Button>
            {/* </OverlayTrigger> */}
          </div>
        </div>
        <div
          style={{
            height: "350px",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Line options={options("Line")} data={graphData} />
        </div>
      </div>
    </>
  );
};

export default OthersPortfolioGraph;
