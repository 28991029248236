import React, { StrictMode, useEffect, useMemo, useState } from "react";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
// import topCompanies from "../../data/topCompanies.json";
import "./home.scss";
import { dataExists, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import SPSidebar from "../header/Sidebar";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import Button from "react-bootstrap/Button";

const TopPresets = ({ lang, mode, user }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState();
  // const [columnDefs, setColumnDefs] = useState([]);
  const [loggedin, setLoggedIn] = useState(false);
  const [presetData, setPresetData] = useState([
    {
      id: 0,
      name: "Select a Preset",
      url: "#",
    },
  ]);
  const [presetName, setPresetName] = useState(null);

  const RankColRender = (p) => {
    return `${p.node.rowIndex + 1}`;
  };

  const savePresets = async (data) => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.savePresets}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        url: data.url,
        name: data.name,
        source_id: data.id,
        created_by: data.created_by,
      };
      try {
        let response = await WebService.post(fullUrl, payload, {
          headers: headers,
        });
        if (response?.status >= 200 && response?.status < 300) {
          window.location.reload();
        } else {
          throw response;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const upvote = async (data) => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.upvote}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        id: data.id,
      };
      try {
        let response = await WebService.post(fullUrl, payload, {
          headers: headers,
        });
        if (response?.status >= 200 && response?.status < 300) {
          window.location.reload();
        } else {
          throw response;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const TodayColRender = (p) => {
    if (p.data?.price_changes === undefined) return null;
    if (isNaN(parseFloat(p.data?.price_changes))) return "-";
    return (
      <div>
        {parseFloat(p.data?.price_changes) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(parseFloat(p.data?.price_changes).toFixed(3))}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(parseFloat(p.data?.price_changes).toFixed(3))}%`}
          </span>
        )}
      </div>
    );
  };
  const peRender = (p) => {
    if (p.data?.pe_ratio_ttm === undefined) return null;
    if (isNaN(parseFloat(p.data?.pe_ratio_ttm))) return "-";
    return (
      <div>
        {parseFloat(p.data?.pe_ratio_ttm).toFixed(3)}
        {/* {parseFloat(p.data?.price_performance_1M) > 0 ? (
        <span className="chart-numbers-change-green">
          <i className="bi bi-arrow-up-short"></i>
          {`${Math.abs(
            parseFloat(p.data?.price_performance_1M).toFixed(3)
          )}%`}
        </span>
      ) : (
        <span className="chart-numbers-change-red">
          <i className="bi bi-arrow-down-short"></i>
          {`${Math.abs(
            parseFloat(p.data?.price_performance_1M).toFixed(3)
          )}%`}
        </span>
      )} */}
      </div>
    );
  };
  const peHistoricalRender = (p) => {
    if (p.data?.pe_ratio_ttm_historical === undefined) return null;
    if (isNaN(parseFloat(p.data?.pe_ratio_ttm_historical))) return "-";
    return (
      <div>
        {parseFloat(p.data?.pe_ratio_ttm_historical).toFixed(3)}
        {/* {parseFloat(p.data?.price_performance_1Y) > 0 ? (
        <span className="chart-numbers-change-green">
          <i className="bi bi-arrow-up-short"></i>
          {`${Math.abs(
            parseFloat(p.data?.price_performance_1Y).toFixed(3)
          )}%`}
        </span>
      ) : (
        <span className="chart-numbers-change-red">
          <i className="bi bi-arrow-down-short"></i>
          {`${Math.abs(
            parseFloat(p.data?.price_performance_1Y).toFixed(3)
          )}%`}
        </span>
      )} */}
      </div>
    );
  };
  const upvotesRender = (p) => {
    if (p.data?.upvotes === undefined) return null;
    return (
      <div className="w-100">
        <span className="me-3">{p.data?.upvotes}</span>
        <i
          onClick={() => {
            if (dataExists(user)) {
              upvote(p?.data);
            }
          }}
          className={`bi ${
            p.data?.voted ? "bi-hand-thumbs-up-fill" : "bi-hand-thumbs-up"
          } ${dataExists(user) ? "cursor-pointer" : ""}`}
        ></i>
      </div>
    );
  };

  const savePresetRender = (p) => {
    if (p.data === undefined) return null;
    return (
      <div className="w-100">
        <Button
          size="sm"
          onClick={() => {
            if (dataExists(user)) {
              savePresets(p?.data);
            }
          }}
          variant={`${p.data?.can_follow ? "primary" : "secondary"}`}
          disabled={!(dataExists(user) && p.data?.can_follow)}
        >
          {localString[lang].save}
        </Button>
      </div>
    );
  };
  const ShareRender = (p) => {
    const [clicked, setClicked] = useState(false);
    if (p.data === undefined) return null;
    return (
      <div className="w-100">
        <i
          onClick={() => {
            let url = `${window.location.origin}${p.data?.url}`;
            navigator.clipboard.writeText(url);
            setClicked(true);
            // navigator
            //   .share({
            //     title: "StockPenguins - Free Global Equities Stock Scanner",
            //     text: "Hey, I found something Interesting. Check this out",
            //     url: p.data?.url,
            //   })
            //   .then(() => {
            //     console.log("Successfully shared");
            //   })
            //   .catch((error) => {
            //     console.error("Something went wrong", error);
            //   });
          }}
          className={`cursor-pointer bi ${clicked ? 'bi-check-all' : 'bi-copy'}`}
        ></i>
      </div>
    );

    // return `$${nFormatter(p.data?.price, 4)}`;
  };
  const NameRender = (p) => {
    return (
      <a target="__blank" href={`${p.data?.url}`}>
        {" "}
        {p.data?.name}{" "}
      </a>
    );
  };

  useEffect(() => {
    setLoggedIn(dataExists(user));
  }, []);

  useEffect(() => {
    if (gridApi) {
      const dataSource = customDataSource();
      gridApi.setGridOption("datasource", dataSource);
    }
  }, [gridApi]);

  const customDataSource = () => {
    return {
      getRows: (params) => {
        setLoading(true);
        const { startRow, endRow } = params;
        const pageSize = endRow - startRow; // Page size
        const pageNumber = startRow / pageSize + 1; // Calculate page number

        let headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        };
        if (!dataExists(user)) {
          headers = {};
        }

        let fullUrl = `${apiEndPoints.getTopPresets}/?page=${pageNumber}&page_size=${pageSize}`;
        WebService.get(fullUrl, { headers: headers })
          .then((response) => {
            let data = response?.data;
            params.successCallback(data?.results, data?.count);
            setLoading(false);
          })
          .catch((error) => {
            params.failCallback();
            console.error("Error fetching data:", error);
            setLoading(false);
          });
      },
    };
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "rank",
      headerName: localString[lang].rank,
      cellRenderer: RankColRender,
      minWidth: 80,
      maxWidth: 80,
      sortable: false,
    },
    {
      field: "name",
      headerName: localString[lang].presetName,
      cellRenderer: NameRender,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "created_by_name",
      headerName: localString[lang].createdBy,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "upvotes",
      headerName: localString[lang].upvotes,
      cellRenderer: upvotesRender,
      minWidth: 120,
      maxWidth: 150,
      sortable: false,
    },
    {
      field: "",
      headerName: localString[lang].savePreset,
      cellRenderer: savePresetRender,
      minWidth: 120,
      maxWidth: 150,
      sortable: false,
    },
    {
      field: "",
      headerName: localString[lang].share,
      cellRenderer: ShareRender,
      minWidth: 100,
      maxWidth: 150,
      sortable: false,
    },
  ]);

  const onSortChanged = (params) => {
    if (gridApi) {
      // const sortModel = gridApi.getSortModel();
      let sortColumns = params["columns"];
      let curSort = sortColumns[sortColumns.length - 1];
      let sortString = `${curSort["sort"] === "desc" ? "-" : ""}${
        curSort["colId"]
      }`;
      // insertParam("o", sortString);
      // Trigger API call with sort info
      // fetchData(sortModel);
    }
  };

  return (
    <>
      <Stack
        className={`content m-4 ${
          mode === "dark" ? "background-dark" : "background-light"
        }`}
        gap={3}
        id="screener-table"
      >
        <div
          className={`ag-theme-${mode === "dark" ? "quartz-dark" : "quartz"}`}
          style={{ height: "calc(100vh - 6rem)" }}
        >
          <AgGridReact
            loading={loading}
            columnDefs={columnDefs}
            autoSizeStrategy={{ type: "fitGridWidth" }}
            rowModelType="infinite"
            pagination={true}
            paginationPageSize={25}
            cacheBlockSize={25}
            onGridReady={onGridReady}
            paginationPageSizeSelector={[25, 50, 100]}
            onSortChanged={onSortChanged}
          />
        </div>
      </Stack>
    </>
  );
};

export default TopPresets;
