import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Home from "../components/home/Home";
import { THEMES } from "../constants";
import HomeTabs from "../components/home/HomeTabs";
import ScrollToTop from "../components/home/ScrollToTop";
import compaines from "../data/companies.json";
import Share from "../components/home/Share";
import axios from "axios";
import SPSidebar from "../components/header/Sidebar";

const HomePage = ({user}) => {
  const [allTickers, setAllTickers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(null);
  const [starredTickers, setStarredTickers] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(localStorage.getItem("isDarkMode"))
  );
  const [lang, setLang] = useState(
    JSON.parse(localStorage.getItem("language")) || "en"
  );

  useEffect(() => {
    if (allTickers === null) {
      let fullUrl = `/assets/data/companies.json`;

      axios.get(fullUrl).then((response) => {
        setAllTickers(response.data);
        // console.log(response.data);
      });
      // setAllTickers(compaines);
    }
    let lsIsDarkMode = JSON.parse(localStorage.getItem("isDarkMode"));
    let isDarkMode = lsIsDarkMode === null ? true : lsIsDarkMode;
    setIsDarkMode(isDarkMode);
    setStarredTickers(JSON.parse(localStorage.getItem("starredTickers")));
    setLoading(false);
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const updateStarred = (item) => {
    let starredTickersList = starredTickers || [];
    let itemIndex = starredTickers?.findIndex((o) => o.ticker === item.ticker);

    if (itemIndex === -1 || itemIndex === undefined) {
      starredTickersList.push(item);
    } else {
      starredTickersList.splice(itemIndex, 1);
    }
    setStarredTickers(JSON.parse(JSON.stringify(starredTickersList)));
    // starredTickersList);
    localStorage.setItem("starredTickers", JSON.stringify(starredTickersList));
  };

  useEffect(() => {
    localStorage.setItem("isDarkMode", isDarkMode);
    const htmlElement = document.querySelector("html");
    htmlElement.setAttribute(
      "data-bs-theme",
      isDarkMode ? THEMES.DARK : THEMES.LIGHT
    );
    setMode(isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <>
      {loading ? (
        <> </>
      ) : (
        <>
          <Header
            toggleDarkMode={toggleDarkMode}
            lang={lang}
            setLang={setLang}
            mode={mode}
            starredTickers={starredTickers}
            user={user}
          />
          <SPSidebar mode={mode} lang={lang} user={user}/>
          <section className="section" id="stock-penguins-section">
            {allTickers && (
              <HomeTabs
                allTickers={allTickers}
                lang={lang}
                mode={mode}
                starredTickers={starredTickers}
                updateStarred={updateStarred}
              />
            )}
          </section>
        </>
      )}
      <Share />
      <ScrollToTop />
    </>
  );
};

export default HomePage;
