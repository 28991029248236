import React, { useEffect, useState } from "react";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { nFormatter, stringFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const SimilarCompanies = ({ ticker, tickerInfo, lang, mode }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [ticker, tickerInfo]);

  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    if (rowData === null) {
      let fullUrl = `${apiEndPoints.similarCompanies}/${ticker}`;
      WebService.get(fullUrl).then((response) => {
        let curData = response?.data;
        setRowData(curData);
      });
    }
  }, []);

  const RankColRender = (p) => {
    return `${p.node.rowIndex + 1}`;
  };

  const OneMonthChangeRender = (p) => {
    if (p.data?.price_performance_1M === undefined) return null;
    if (isNaN(parseFloat(p.data?.price_performance_1M))) return "-";
    return (
      <div>
        {parseFloat(p.data?.price_performance_1M) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1M).toFixed(3)
            )}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1M).toFixed(3)
            )}%`}
          </span>
        )}
      </div>
    );
  };
  const OneYearChangeRender = (p) => {
    if (p.data?.price_performance_1Y === undefined) return null;
    if (isNaN(parseFloat(p.data?.price_performance_1Y))) return "-";
    return (
      <div>
        {parseFloat(p.data?.price_performance_1Y) > 0 ? (
          <span className="chart-numbers-change-green">
            <i className="bi bi-arrow-up-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1Y).toFixed(3)
            )}%`}
          </span>
        ) : (
          <span className="chart-numbers-change-red">
            <i className="bi bi-arrow-down-short"></i>
            {`${Math.abs(
              parseFloat(p.data?.price_performance_1Y).toFixed(3)
            )}%`}
          </span>
        )}
      </div>
    );
  };
  const MarketCapRender = (p) => {
    if (p.data?.market_cap === undefined) return null;
    return `$${nFormatter(p.data?.market_cap, 4)}`;
  };
  const PriceRender = (p) => {
    if (p.data?.price === undefined) return null;
    return `$${nFormatter(p.data?.price, 4)}`;
  };
  const NameRender = (p) => {
    return (
      <a target="__blank" href={`/companies/stock-metrics/${p.data?.symbol}`}>
        {" "}
        {p.data.company_name}{" "}
      </a>
    );
  };

  const peRender = (p) => {
    if (p.data?.pe_ratio_ttm === undefined) return null;
    if (isNaN(parseFloat(p.data?.pe_ratio_ttm))) return "-";
    return <div>{parseFloat(p.data?.pe_ratio_ttm).toFixed(3)}</div>;
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "rank",
      headerName: localString[lang].rank,
      cellRenderer: RankColRender,
      maxWidth: 100,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "company_name",
      headerName: localString[lang].companyName,
      cellRenderer: NameRender,
      minWidth: 200,
      sortable: false,
    },
    {
      field: "symbol",
      headerName: localString[lang].symbol,
      minWidth: 100,
      maxWidth: 120,
      sortable: false,
    },
    {
      field: "sector",
      headerName: localString[lang].Sector,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "market_cap",
      headerName: localString[lang].marketCap,
      cellRenderer: MarketCapRender,
      minWidth: 120,
      sortable: false,
    },
    {
      field: "price",
      headerName: localString[lang].price,
      cellRenderer: PriceRender,
      minWidth: 120,
      maxWidth: 120,
      sortable: true,
    },
    {
      field: "price_performance_1M",
      headerName: localString[lang].oneMonthChange,
      cellRenderer: OneMonthChangeRender,
      minWidth: 150,
      maxWidth: 150,
      sortable: true,
    },
    {
      field: "price_performance_1Y",
      headerName: localString[lang].oneYearChange,
      cellRenderer: OneYearChangeRender,
      minWidth: 150,
      maxWidth: 150,
      sortable: true,
    },
    {
      field: "pe_ratio_ttm",
      headerName: localString[lang].PE,
      cellRenderer: peRender,
      minWidth: 150,
      maxWidth: 150,
      sortable: true,
    },
    {
      field: "ps_ratio_ttm",
      headerName: localString[lang].PS,
      cellRenderer: peRender,
      minWidth: 120,
      sortable: true,
    },
    {
      field: "pb_ratio_ttm",
      headerName: localString[lang].PB,
      cellRenderer: peRender,
      minWidth: 120,
      sortable: true,
    },
  ]);

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Stack
          className={`content  ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
          gap={3}
        >
          <div className="d-flex flex-row flex-wrap bd-highlight data-container">
            <div className="p-2 bd-highlight flex-fill">
              {/* <div className="p-2 bd-highlight flex-column"> */}
              <div className="data-title">
                {localString[lang].SimilarCompanies}
              </div>
              <div
                className={`ag-theme-${
                  mode === "dark" ? "quartz-dark" : "quartz"
                }`}
                style={{ height: "300px" }}
              >
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  autoSizeStrategy={{ type: "fitGridWidth" }}
                  pagination={true}
                  paginationPageSize={25}
                  paginationPageSizeSelector={[25, 50, 100]}
                />
              </div>
            </div>
          </div>
        </Stack>
      )}
    </>
  );
};

export default SimilarCompanies;
