import React, { useState, useEffect } from "react";
import { Accordion, Row, Col, Container, Table, Button } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { dataExists, nFormatter } from "../../helpers";
import { apiEndPoints } from "../../services/endpoints";
import { WebService } from "../../services/webServices";
import Modal from "react-bootstrap/Modal";

const SellModal = ({ show, onHide, transaction, user }) => {
  const [price, setPrice] = useState("");
  const [date, setDate] = useState("");
  const [shareCount, setShareCount] = useState("");
  const [error, setError] = useState("");

  const sell = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.sell}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        id: transaction.id,
        no_of_shares: shareCount,
        price: price,
        date: date,
      };
      try {
        let response = await WebService.post(fullUrl, payload, {
          headers: headers,
        });
        console.log(response)
        if (response?.status >= 200 && response?.status < 300) {
          window.location.reload();
        } else {
          throw response;
        }
      } catch (error) {
        setError(error?.message)
        console.log(error);
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      //   size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="contained-modal-title-vcenter">Login</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="m-auto">
        <form onSubmit={sell}>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text rounded-end-0" id="sell-symbol">
                Symbol
              </span>
            </div>
            <input
              // onChange={(e) => setFirstName(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Symbol"
              aria-label="Symbol"
              aria-describedby="sell-symbol"
              value={transaction?.symbol}
              disabled
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text rounded-end-0" id="sell-date">
                Date
              </span>
            </div>
            <input
              onChange={(e) => setDate(e.target.value)}
              type="date"
              className="form-control"
              placeholder="Date"
              aria-label="Date"
              aria-describedby="sell-date"
              value={date}
              min={transaction?.date_of_purchase}
              required
            />
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text rounded-end-0" id="sell-price">
                Price
              </span>
            </div>
            <input
              onChange={(e) => setPrice(e.target.value)}
              type="number"
              className="form-control"
              placeholder={transaction?.current_price}
              aria-label="Price"
              aria-describedby="sell-price"
              value={price}
              required
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text rounded-end-0" id="sell-count">
                No of Shares
              </span>
            </div>
            <input
              onChange={(e) => {
                let curValue = parseInt(e.target.value);
                let finalValue = 0;
                if (isNaN(curValue)) {
                  finalValue = "";
                } else if (curValue < 0) {
                  finalValue = 0;
                } else if (curValue > transaction?.no_of_shares) {
                  finalValue = transaction?.no_of_shares;
                } else {
                  finalValue = curValue;
                }
                // if (parseInt())
                setShareCount(finalValue);
              }}
              type="number"
              className="form-control"
              placeholder="No of Shares"
              aria-label="No of Shares"
              aria-describedby="sell-count"
              min="0"
              max={transaction?.no_of_shares}
              step="1"
              value={shareCount}
              required
            />
          </div>
          {error}
          <div className="text-lg-center">
            <button type="submit" className="btn btn-primary px-5">
              Sell
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SellModal;
