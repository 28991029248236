import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Home from "../components/home/Home";
import { THEMES } from "../constants";
import HomeTabs from "../components/home/HomeTabs";
import ScrollToTop from "../components/home/ScrollToTop";
import compaines from "../data/companies.json";
import Share from "../components/home/Share";
import axios from "axios";
import SPSidebar from "../components/header/Sidebar";
import Stack from "react-bootstrap/Stack";

const Terms = ({ user }) => {
  const [allTickers, setAllTickers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(null);
  const [starredTickers, setStarredTickers] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(localStorage.getItem("isDarkMode"))
  );
  const [lang, setLang] = useState(
    JSON.parse(localStorage.getItem("language")) || "en"
  );

  useEffect(() => {
    if (allTickers === null) {
      let fullUrl = `/assets/data/companies.json`;

      axios.get(fullUrl).then((response) => {
        setAllTickers(response.data);
        // console.log(response.data);
      });
      // setAllTickers(compaines);
    }
    let lsIsDarkMode = JSON.parse(localStorage.getItem("isDarkMode"));
    let isDarkMode = lsIsDarkMode === null ? true : lsIsDarkMode;
    setIsDarkMode(isDarkMode);
    setStarredTickers(JSON.parse(localStorage.getItem("starredTickers")));
    setLoading(false);
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const updateStarred = (item) => {
    let starredTickersList = starredTickers || [];
    let itemIndex = starredTickers?.findIndex((o) => o.ticker === item.ticker);

    if (itemIndex === -1 || itemIndex === undefined) {
      starredTickersList.push(item);
    } else {
      starredTickersList.splice(itemIndex, 1);
    }
    setStarredTickers(JSON.parse(JSON.stringify(starredTickersList)));
    // starredTickersList);
    localStorage.setItem("starredTickers", JSON.stringify(starredTickersList));
  };

  useEffect(() => {
    localStorage.setItem("isDarkMode", isDarkMode);
    const htmlElement = document.querySelector("html");
    htmlElement.setAttribute(
      "data-bs-theme",
      isDarkMode ? THEMES.DARK : THEMES.LIGHT
    );
    setMode(isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <>
      {loading ? (
        <> </>
      ) : (
        <>
          <Header
            toggleDarkMode={toggleDarkMode}
            lang={lang}
            setLang={setLang}
            mode={mode}
            starredTickers={starredTickers}
            user={user}
          />
          <SPSidebar mode={mode} lang={lang} user={user} />
          <section className="section" id="stock-penguins-section">
            <Stack
              className={`content ${
                mode === "dark" ? "background-dark" : "background-light"
              }`}
              gap={3}
            >
              <div className="container mt-5">
                <div className="mb-4">
                  <h1>Terms & Conditions</h1>
                  <p>
                    <strong>Effective Date:</strong> 1 Octobet, 2024
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Welcome to StockPenguins.com</h3>
                  <p>
                    These Terms govern your use of the StockPenguins.com mobile
                    application ("App"). By using the App, you agree to these
                    Terms.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Use of the App</h3>
                  <p>
                    The App is provided for informational purposes only and is
                    not a substitute for professional financial advice.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Content</h3>
                  <p>
                    Information on the App is for general informational purposes
                    and should not be considered investment advice.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Disclaimer of Warranties</h3>
                  <p>
                    The App is provided "as is" without warranties of any kind.
                    StockPenguins.com disclaims all warranties, including
                    merchantability and fitness for a particular purpose.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Limitation of Liability</h3>
                  <p>
                    StockPenguins.com will not be liable for any damages arising
                    from your use of the App.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Termination</h3>
                  <p>
                    We may terminate your access to the App at any time, for any
                    reason.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Changes to these Terms & Conditions</h3>
                  <p>
                    We may update these Terms & Conditions from time to time and
                    post any changes on this page.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Governing Law</h3>
                  <p>
                    These Terms are governed by the laws of the State of [Insert
                    Your State], without regard to conflict of law provisions.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Entire Agreement</h3>
                  <p>
                    These Terms constitute the entire agreement between you and
                    StockPenguins.com regarding your use of the App.
                  </p>
                </div>

                <div className="mb-4">
                  <h3>Contact Us</h3>
                  <p>
                    If you have any questions, please contact us at
                    stockpenguins@gmail.com.
                  </p>
                </div>
              </div>
            </Stack>
          </section>
        </>
      )}
      <Share />
      <ScrollToTop />
    </>
  );
};

export default Terms;
