import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import { LANGUAGES } from "../../constants";
import "./header.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { localString } from "../../localization/localString";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Login from "../home/Login";
import { dataExists } from "../../helpers";

const Header = ({
  isDarkMode,
  toggleDarkMode,
  lang,
  setLang,
  starredTickers,
  mode,
  user,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showMenu, setShowMenu] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const [indexDataLoaded, setindexDataLoaded] = useState(false);
  const [spxChange, setSpxChange] = useState(null);
  const [dowChange, setDowChange] = useState(null);
  const [nasdaqChange, setNasdaqChange] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  const logout = () => {
    // setAccessTokenToHeader();
    localStorage.setItem("accessToken", null);
    localStorage.setItem("refreshToken", null);
    localStorage.setItem("user", null);
    window.location = "/";
  };

  useEffect(() => {
    if (indexDataLoaded === false) {
      axios.get(`/assets/data/spx.json`).then((response) => {
        let data = response.data;
        let histObj = JSON.parse(data?.data?.hist);
        let priceHistory = histObj.Close;
        let values = [];
        for (let time in priceHistory) {
          values.push(priceHistory[time]);
        }
        let firstValue = parseFloat(values[values.length - 2]);
        let currentValue = parseFloat(values[values.length - 1]);
        let pctChange = (currentValue / firstValue - 1) * 100;
        setSpxChange(pctChange);
      });
      axios.get(`/assets/data/dji.json`).then((response) => {
        let data = response.data;
        let histObj = JSON.parse(data?.data?.hist);
        let priceHistory = histObj.Close;
        let values = [];
        for (let time in priceHistory) {
          values.push(priceHistory[time]);
        }
        let firstValue = parseFloat(values[values.length - 2]);
        let currentValue = parseFloat(values[values.length - 1]);
        let pctChange = (currentValue / firstValue - 1) * 100;

        setDowChange(pctChange);
      });
      axios.get(`/assets/data/ixic.json`).then((response) => {
        let data = response.data;
        let histObj = JSON.parse(data?.data?.hist);
        let priceHistory = histObj.Close;
        let values = [];
        for (let time in priceHistory) {
          values.push(priceHistory[time]);
        }
        let firstValue = parseFloat(values[values.length - 2]);
        let currentValue = parseFloat(values[values.length - 1]);
        let pctChange = (currentValue / firstValue - 1) * 100;
        setNasdaqChange(pctChange);
      });
      setindexDataLoaded(true);
    }
  }, []);

  return (
    <header className="header">
      <div className="h-100 d-flex align-items-center justify-content-between">
        <Link to="/">
          <img className="logo" src="/assets/logo.png" alt="logo" />
        </Link>
        <div className="d-flex">
          <div
            className={`align-items-center nav-link-hidden ${
              mode === "dark" ? "link-background-dark" : "link-background-light"
            }`}
          >
            <Link
              className={`me-2 no-underline ${
                mode === "dark" ? "color-text-dark" : "color-text-light"
              }`}
              to="/index/spx"
            >
              <span>
                SPX
                <span
                  className={`${spxChange >= 0 ? "index-green" : "index-red"}`}
                >
                  {`(${spxChange >= 0 ? "+" : "-"}${Math.abs(spxChange).toFixed(
                    2
                  )}%)`}
                </span>
              </span>
            </Link>
          </div>
          <div
            className={`align-items-center nav-link-hidden ${
              mode === "dark" ? "link-background-dark" : "link-background-light"
            }`}
          >
            <Link
              className={`me-2 no-underline ${
                mode === "dark" ? "color-text-dark" : "color-text-light"
              }`}
              to="/index/dji"
            >
              <span>
                DOW
                <span
                  className={`${dowChange >= 0 ? "index-green" : "index-red"}`}
                >
                  {`(${dowChange >= 0 ? "+" : "-"}${Math.abs(dowChange).toFixed(
                    2
                  )}%)`}
                </span>
              </span>
            </Link>
          </div>
          <div
            className={`align-items-center nav-link-hidden ${
              mode === "dark" ? "link-background-dark" : "link-background-light"
            }`}
          >
            <Link
              className={`me-2 no-underline ${
                mode === "dark" ? "color-text-dark" : "color-text-light"
              }`}
              to="/index/ixic"
            >
              <span>
                Nasdaq
                <span
                  className={`${
                    nasdaqChange >= 0 ? "index-green" : "index-red"
                  }`}
                >
                  {`(${nasdaqChange >= 0 ? "+" : "-"}${Math.abs(
                    nasdaqChange
                  ).toFixed(2)}%)`}
                </span>
              </span>
            </Link>
          </div>
          {/* <div className="align-items-center nav-link-hidden">
            <Dropdown className="m-2">
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                {localString[lang].pages}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item key={"compare"} href={`/compare`}>
                  {localString[lang].compare}
                </Dropdown.Item>
                <Dropdown.Item key={"top500"} href={`/companies/stock-metrics`}>
                  {localString[lang].topCompanies}
                </Dropdown.Item>
                <Dropdown.Item key={"screener"} href={`/screener`}>
                  {localString[lang].screener}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
          {starredTickers?.length > 0 && (
            <Dropdown className="m-2">
              <Dropdown.Toggle variant="info" id="dropdown-basic">
                {localString[lang].watchlist}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {starredTickers?.map((ticker) => {
                  return (
                    <Dropdown.Item
                      key={ticker.ticker}
                      href={`/companies/stock-metrics/${ticker.ticker}`}
                    >
                      {ticker.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
          <div className="d-flex align-items-center justify-content-between">
            <Form.Select
              aria-label="Lang select"
              className="nav-link-hidden"
              value={lang}
              onChange={(e) => {
                localStorage.setItem(
                  "language",
                  JSON.stringify(e.target.value)
                );
                setLang(e.target.value);
              }}
            >
              {LANGUAGES.map((lang) => {
                return (
                  <option key={lang.value} value={lang.value}>
                    {lang.label}
                  </option>
                );
              })}
            </Form.Select>
            <div className="cursor-pointer ms-3 nav-link-hidden">
              <Link to="mailto:support@stockpenguins.com" target="_blank">
                <i className="bi bi-envelope-at-fill"></i>
              </Link>
            </div>
            <div className="cursor-pointer ms-3 nav-link-hidden">
              <Link to="https://discord.gg/BS8Jbq3c" target="_blank">
                <i className="bi bi-discord"></i>
              </Link>
            </div>
            <div className="cursor-pointer ms-3 nav-link-hidden">
              <Link to="https://t.me/stockpenguins" target="_blank">
                <i className="bi bi-telegram"></i>
              </Link>
            </div>
            <div className="cursor-pointer ms-3">
              {isDarkMode ? (
                <i
                  className="bi bi-brightness-high-fill"
                  onClick={toggleDarkMode}
                ></i>
              ) : (
                <i className="bi bi-moon" onClick={toggleDarkMode}></i>
              )}
            </div>
            {dataExists(user) === false ? (
              <Button
                className="ms-3"
                variant="primary"
                onClick={() => setShowLogin(true)}
              >
                Login
              </Button>
            ) : (
              <></>
            )}
            <NavDropdown
              title={
                <span onClick={() => setShowMenu(true)}>
                  <i className="bi bi-list"></i>
                </span>
              }
              id="collapsible-nav-dropdown"
              className="nav-dropdown"
              show={showMenu}
            >
              <NavDropdown.Item href="/index/spx">
                <span>
                  SPX
                  <span
                    className={`${
                      spxChange >= 0 ? "index-green" : "index-red"
                    }`}
                  >
                    {`(${spxChange >= 0 ? "+" : "-"}${Math.abs(
                      spxChange
                    ).toFixed(2)}%)`}
                  </span>
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item href="/index/dji">
                <span>
                  DOW
                  <span
                    className={`${
                      dowChange >= 0 ? "index-green" : "index-red"
                    }`}
                  >
                    {`(${dowChange >= 0 ? "+" : "-"}${Math.abs(
                      dowChange
                    ).toFixed(2)}%)`}
                  </span>
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item href="/index/dji">
                <span>
                  Nasdaq
                  <span
                    className={`${
                      nasdaqChange >= 0 ? "index-green" : "index-red"
                    }`}
                  >
                    {`(${nasdaqChange >= 0 ? "+" : "-"}${Math.abs(
                      nasdaqChange
                    ).toFixed(2)}%)`}
                  </span>
                </span>
              </NavDropdown.Item>

              <NavDropdown.Item href="/companies/stock-metrics">
                {localString[lang].Stock}
              </NavDropdown.Item>
              <NavDropdown.Item href="/screener">
                {localString[lang].screener}
              </NavDropdown.Item>
              <NavDropdown.Item href="/compare">
                {localString[lang].compare}
              </NavDropdown.Item>
              <NavDropdown.Item href="/top-companies">
                {localString[lang].topCompanies}
              </NavDropdown.Item>
              <NavDropdown.Item href="/top-presets">
                {localString[lang].topPresets}
              </NavDropdown.Item>
              {dataExists(user) && (
                <NavDropdown.Item href="/portfolio">
                  {localString[lang].myPortfolio}
                </NavDropdown.Item>
              )}
              <NavDropdown.Item href="/portfolio-leaderboard">
                {localString[lang].portfolioLeader}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="mailto:support@stockpenguins.com"
                target="_blank"
              >
                {localString[lang].contactUs}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://discord.gg/BS8Jbq3c"
                target="_blank"
              >
                {localString[lang].joinDiscord}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://t.me/stockpenguins"
                target="_blank"
              >
                {localString[lang].joinTelegram}
              </NavDropdown.Item>
              {dataExists(user) && (
                <NavDropdown.Item>
                  <span onClick={() => logout()}>
                    {localString[lang].logout}
                  </span>
                </NavDropdown.Item>
              )}

              <NavDropdown.Divider />
              <NavDropdown.Item>
                <Form.Select
                  aria-label="Lang select"
                  value={lang}
                  onChange={(e) => {
                    localStorage.setItem(
                      "language",
                      JSON.stringify(e.target.value)
                    );
                    setLang(e.target.value);
                    setShowMenu(false);
                  }}
                >
                  {LANGUAGES.map((lang) => {
                    return (
                      <option key={lang.value} value={lang.value}>
                        {lang.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </div>
      <Login show={showLogin} onHide={() => setShowLogin(false)} lang={lang} />
    </header>
  );
};

export default Header;
