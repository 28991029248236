import React, { useEffect, useState } from "react";
import Graph from "../home/Graph";
import { BILLION, COLORS, DURATION, MILLION } from "../../constants";
import { localString } from "../../localization/localString";
import moment from "moment";

const SharesIssued = ({
  index,
  selectedId,
  setSelectedId,
  duration,
  ticker,
  rawData,
  lang,
  mode,
}) => {
  const [labels, setLabels] = useState([]);
  const [historicalData, setHistoricalData] = useState([]);
  const [titleValue, setTitleValue] = useState(null);
  const [titleChange, setTitleChange] = useState(null);

  useEffect(() => {
    if (ticker !== null && ticker !== "" && ticker?.length !== 0) {
      let dateString = [];
      let shareHistory = [];
      for (let i = 0; i < rawData?.length; i++) {
        let timestamp = rawData[i]["date"];
        let dateFormat = duration === DURATION.QUARTERLY ? "[Q]Q-YYYY" : "YYYY";
        let thisDate = moment(timestamp).format(dateFormat);
        dateString.push(thisDate);
        shareHistory.push(rawData[i]["outstandingShares"]);
      }
      let currentValue =
        shareHistory?.length > 0 ? shareHistory[shareHistory.length - 1] : "";

      let preValue =
        duration === DURATION.QUARTERLY && shareHistory?.length > 0
          ? shareHistory[shareHistory.length - 91]
          : shareHistory[shareHistory.length - 366];

      let percentageChange = ((currentValue - preValue) * 100) / preValue;
      // percentageChange =
      //   duration === DURATION.QUARTERLY ? threeMonthGrowth : oneYearGrowth;
      let val = isNaN(parseFloat(currentValue).toFixed(2))
        ? "-"
        : `${parseFloat(parseFloat(currentValue) / MILLION).toFixed(2)}M`;

      setTitleValue(val);
      setTitleChange(percentageChange);

      setLabels(dateString);
      setHistoricalData(shareHistory);
    }
  }, [ticker, duration, rawData]);

  const tooltipFormatter = (tooltipItem) => {
    return `${tooltipItem.formattedValue}`;
  };

  const yaxisFormatter = (label) => {
    return `${parseFloat(label).toFixed(0) / MILLION}M`;
  };

  const data = () => {
    return {
      labels,
      datasets: [
        {
          data: historicalData,
          borderColor: COLORS.GRAPH.SHARES_ISSUED.borderColor,
          backgroundColor: COLORS.GRAPH.SHARES_ISSUED.backgroundColor,
        },
      ],
    };
  };

  return (
    <Graph
      index={index}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      chartType={"Bar"}
      data={data}
      description={localString[lang].description.SharesIssued}
      title={`${localString[lang].titles.SharesIssued}`}
      lang={lang}
      tooltipFormatter={tooltipFormatter}
      yaxisFormatter={yaxisFormatter}
      mode={mode}
      titleValue={titleValue}
      titleChange={titleChange}
      duration={duration}
      subTitle={
        duration === DURATION.QUARTERLY
          ? localString[lang].comparedToLastQuarter
          : localString[lang].comparedToLastYear
      }
    />
  );
};

export default SharesIssued;
