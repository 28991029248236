import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home";
import "./styles/style.css";
import Compare from "./pages/Compare";
import TopCompaniesPage from "./pages/TopCompanies";
import Screener from "./pages/Screener";
import Index from "./pages/Index";
import { setAccessTokenToHeader } from "./services/helper";
import TopPresetsPage from "./pages/TopPresets";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PersonalPortfolio from "./pages/PersonalPortfolio";
import PortfolioLeaderboard from "./pages/PortfolioLeaderboard";
import UserPortfolios from "./pages/UserPortfolios";

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    if (user?.token) {
      setAccessTokenToHeader(user.token);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<TopCompaniesPage user={user} showSearch={true}/>} />
        {/* <Route path='/login' element={<LoginPage />} /> */}
        {/* <Route path='/' element={<HomePage />} /> */}
        <Route path="/compare" element={<Compare user={user} />} />
        <Route
          path="/companies/stock-metrics"
          element={<HomePage user={user} />}
        />
        <Route
          path="/top-companies"
          element={<TopCompaniesPage user={user} />}
        />
        <Route
          path="/terms"
          element={<Terms user={user} />}
        />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy user={user} />}
        />
        <Route
          path="/top-presets"
          element={<TopPresetsPage user={user} />}
        />
        <Route
          path="/portfolio"
          element={<PersonalPortfolio user={user} />}
        />
        <Route
          path="/portfolio-leaderboard"
          element={<PortfolioLeaderboard user={user} />}
        />
        <Route path="/screener" element={<Screener user={user} />} />
        <Route
          path="/companies/stock-metrics/:id"
          element={<HomePage user={user} />}
        />
        <Route
          path="/portfolio/:id"
          element={<UserPortfolios user={user} />}
        />

        <Route path="/index/:id" element={<Index user={user} />} />
      </Routes>
    </Router>
  );
}

export default App;
